import gql from 'graphql-tag';

const query = gql`
query getUsers{
    getUsers{
            _id      
            organizationId
            firstName
            lastName
            firebaseId
            title
            email
            phoneNumber
            phoneNumberExt
            photoUrl
            role
            active
            passwordResetCode
            meta{
                createdBy
                createdDate
                modifiedBy
                modifiedDate
            }       
    }

}
`;

export default query;