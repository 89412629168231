import gql from 'graphql-tag';

const query = gql`
query getDevices{
    getDevices{
        _id
        organizationId
        serialNumber
        pairingCode
        active
        deviceModelId
        softwareVersion
        assetId
        assignedUserId
        lastUsedAt
        user{
            _id      
            organizationId
            firstName
            lastName
            firebaseId
            title
            email
            phoneNumber
            phoneNumberExt
            photoUrl
            role
            active
            passwordResetCode
            meta{
                createdBy
                createdDate
                modifiedBy
                modifiedDate
            }           }
            organization{
                       _id
        name
     ownerFirstName
     ownerLastName
     ownerTitle
     ownerPhone
     ownerEmail
     address{
         addressLine1
         addressLine2
         city
         state
         country
     }
     active
     billing{
      planId
      status
      stripeCustomerId
      paidThrough
      nextPaymentDate
      paymentMethodDesc
     }
     payments{
         stripeChargeId
         paidDateTime
         amount
         taskId
     }
            }
            deviceModel {
            _id 
              name
              price
              active
              meta{
                  createdBy
                  createdDate
                  modifiedBy
                  modifiedDate
              }
            }            
        }

}
`;

export default query;