import gql from 'graphql-tag';

const mutation = gql`
mutation loginToOrganization($organizationId: String!) {
    loginToOrganization(organizationId: $organizationId) {
         _id
        organizationId
        firstName
        lastName
        firebaseId
        title
        photoUrl
        email
        phoneNumber
        photoUrl
        role
        active
        passwordResetCode
      organization {
        _id
        name
        ownerFirstName
        ownerLastName
        ownerTitle
        ownerPhone
        ownerEmail
        address {
          addressLine1
          addressLine2
          city
          state
          country
        }
        active
        billing{
          planId
          status
          stripeCustomerId
          paidThrough,
          nextPaymentDate
          paymentMethodDesc
          cardDetails{
                brand
                expMonth
                expYear
                lastDigits
          },
        }
        payments {
          stripeChargeId
          paidDateTime
          amount
          taskId
        }
      }
      meta {
        createdBy
        createdDate
        modifiedBy
        modifiedDate
      }
  }
  }
`;

export default mutation;
