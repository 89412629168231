import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement, CardElement } from '@stripe/react-stripe-js';
import Input from '../elements/Input';
import { Colors } from '../../util/Colors';
import { Container } from '../Header';
import * as Polished from "polished";
import { Form, Row, Spacer } from '../layouts/AuthLayout';
import StyledSelect from "../../components/elements/DropDown";
import { Countries, Label, RequiredInput } from '../../pages/AddAccount';
import Button from '../elements/button/Button';
import { useNavigate } from "react-router-dom";
import { USstates } from '../../models/enums/USStatesEnum';
import Icon, { Icons } from '../elements/Icon';
import { StripeElementStyle } from "@stripe/stripe-js";
import Joi from "joi";
import * as Schema from "../../util/Schema";
import makeEventHandler from '../../util/MakeEventHandler';
import ErrorText from '../elements/ErrorText';
import { useMutation } from '@apollo/client';
import GET_USER from "../../graphql/queries/getUserByFirebaseId.query";
import UPDATE_PAYMENT_MUTATION from '../../graphql/mutations/updatePaymentMethod.mutation';
import toast from 'react-hot-toast';
import * as ErrorUtil from "../../util/ErrorUtil";
import { successMessages } from '../../config/messages.config';

const schema = Joi.object().keys({
  name: Schema.stripe.name().error(([error]) => {
    const message = "Card holder name is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),

  cardNumber: Schema.stripe.cardNumber().error(([error]) => {
    const message = "Card number is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),

  cvc: Schema.stripe.cvc().error(([error]) => {
    const message = "CVC is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),

  expDate: Schema.stripe.expDate().error(([error]) => {
    const message = "ExpDate is invalid";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),

  email: Schema.stripe.email().error(([error]) => {
    const message = "You have entered an invalid email address!";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),

  address_line1: Schema.stripe.addressLine1().error(([error]) => {
    const message = "Address Line 1 is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  address_city: Schema.stripe.city().error(([error]) => {
    const message = "City is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  address_state: Schema.stripe.state().error(([error]) => {
    const message = "State is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  address_zip: Schema.stripe.zip().error(([error]) => {
    const message = "ZIP/Postal Code is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),
  address_country: Schema.stripe.country().error(([error]) => {
    const message = "Country is required";
    return new Error(
      JSON.stringify({
        field: error.path[0],
        message,
      })
    );
  }),

})


const style: StripeElementStyle = {
  base: {
    fontSize: "13px",
    fontWeight: "600",
    "::placeholder": {
      color: Colors.grey3,
    },
    ":focus": {},
  },
  invalid: {
    color: Colors.red,
  },
};

const SubForm = styled.div`
  display: flex;
  column-gap: 8px;
   @media screen and (max-width: 450px) {
      flex-direction: column;
   }
  `;

const FormGroup = styled.fieldset`
    padding: 0;
    border-style: none;
    will-change: opacity, transform;
    border-radius: 4px;
    width: 100%;
    `;

export const DropDown = styled.div`
    width: 100%;
    margin-top: 18px;
`;

type ElementContainerProps = {
  focused: boolean;
};
const CardCvcNumber = styled.div`
  display: flex;
  width: 100%;
  grid-gap: 15px;
`;
const ElementContainer = styled.div<ElementContainerProps>`
  position: relative;
  flex: 1;
  .Element {
    border: 1px solid
    ${(props) => {
    if (props.focused) return Colors.grey4;
    return Colors.grey5;
  }};
    height: 40px;
    width: 100%;
    padding: 0px 10px;
    border-radius: 5px;
    background: #FFFFFF;
    box-sizing: border-box;
    outline: none;
    font-size: 16px;
    display: grid;
    align-items: center;
    padding-left: 40px;
      &:hover {
        border: 1px solid
          ${(props) => {
    if (props.focused) return Colors.grey4;
    return Polished.darken(0.05, Colors.grey5);
  }};
      }
    .__PrivateStripeElement{
      height: auto !important;
      margin-top: 7px;
    }
  } 
`;

enum ElementsEnum {
  CardNumber = 'CardNumber',
  CVC = 'CVC',
  ExpDate = 'ExpDate',
};

enum ErrorKeyEnum {
  Name = "name",
  CardNumber = 'cardNumber',
  CVC = 'cvc',
  ExpDate = 'expDate',
  Email = "email",
  AddressLine1 = "address_line1",
  City = "address_city",
  State = "address_state",
  Zip = "address_zip",
  Country = "address_country",
}

interface IbillingDetails {
  name: string,
  cardNumber?: boolean,
  cvc?: boolean,
  expDate?: boolean,
  email: string,
  address_line1: string,
  address_city: string,
  address_state: string,
  address_zip: string,
  address_country: string
}

export default function StripeDetailsModal({ close }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [billingDetails, setBillingDetails] = useState<IbillingDetails>({
    name: "",
    cardNumber: true || undefined,
    cvc: true,
    expDate: true,
    email: "",
    address_line1: "",
    address_city: "",
    address_state: "",
    address_zip: "",
    address_country: ""
  });
  const [focused, setFocus] = React.useState<ElementsEnum | null>(null);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [fieldErrors, setFieldErrorsInternal] = React.useState({
    [ErrorKeyEnum.Name]: null,
    [ErrorKeyEnum.CardNumber]: null,
    [ErrorKeyEnum.CVC]: null,
    [ErrorKeyEnum.ExpDate]: null,
    [ErrorKeyEnum.Email]: null,
    [ErrorKeyEnum.AddressLine1]: null,
    [ErrorKeyEnum.City]: null,
    [ErrorKeyEnum.State]: null,
    [ErrorKeyEnum.Zip]: null,
    [ErrorKeyEnum.Country]: null,
  });

  const eventHandler = makeEventHandler(() => setError(""));

  const setFieldErrors = (field: string, message: string | null) => {
    const newFieldErrors: any = {
      [field]: message,
    };
    setFieldErrorsInternal(newFieldErrors);
  };

  const [updatePaymentMutation] = useMutation(UPDATE_PAYMENT_MUTATION, {
    refetchQueries: [{
      query: GET_USER
    }],
    onCompleted: async (data) => {
      toast.success(successMessages.STRIPE_PAYMENT_UPDATE_SUCCESS)
      close();

    }, onError: async (error) => {
      const errorMsg = ErrorUtil.getErrorMessage(error);
      if (error) {
        toast.error("There was an error updating your payment method. Please contact support@gaize.ai for assistance.");
      }
      setLoading(false);

    },
  })

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { error: schemaError, value } = schema.validate(billingDetails);
    if (schemaError) {
      const { field, message } = JSON.parse(schemaError.message);
      setFieldErrors(field, message);
      return
    }
    setError("");
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    if (!card) return;
    delete billingDetails?.cardNumber;
    delete billingDetails?.cvc;
    delete billingDetails?.expDate;
    const { error, token } = await stripe.createToken(card, billingDetails);
    setLoading(true);
    updatePaymentMutation({
      variables: {
        "params": {
          "name": billingDetails.name,
          "email": billingDetails.email,
          "stripeToken": token?.id,
          "addressline1": billingDetails.address_line1,
          "city": billingDetails.address_city,
          "state": billingDetails.address_state,
          "zip": billingDetails.address_zip,
          "country": billingDetails.address_country
        }
      }
    });

  };


  type selectedStateType = {
    value: string,
    label: string
  }
  const [selectedState, setSelectedState] = React.useState<selectedStateType | null>(() => {
    return null
  });
  const [selectedCountry, setSelectedCountry] = React.useState<selectedStateType | null>(() => {
    return null
  });

  // enum to array conversion
  const statesArray = [] as any;
  for (const [propertyKey, propertyValue] of Object.entries(USstates)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    statesArray.push({ label: propertyValue, value: propertyValue });
  }
  statesArray.splice(0, 1);

  const countryOptions = [] as any;
  for (const [propertyKey, propertyValue] of Object.entries(Countries)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    countryOptions.push({ label: propertyValue, value: propertyValue });
  }
  countryOptions.splice(0, 1);

  const isFocused = (field: ElementsEnum) => field === focused;
  const iconColor = (field: ElementsEnum) =>
    isFocused(field) ? Colors.grey3 : Colors.grey4;


  return (
    <>
      <Container>
        <RequiredInput>
          <Form onSubmit={handleSubmit}>
            <Row margin>
              <Input
                header="Card Holder Name"
                id="name"
                type="text"
                placeholder="Card Holder Name"
                error={fieldErrors[ErrorKeyEnum.Name]}
                value={billingDetails.name}
                onChange={(e) => {
                  setFieldErrors(ErrorKeyEnum.Name, null);
                  setBillingDetails({ ...billingDetails, name: e.target.value });
                }}
              />
            </Row>
            <Row margin={!fieldErrors[ErrorKeyEnum.CardNumber]}>
              <ElementContainer focused={isFocused(ElementsEnum.CardNumber)}>
                <Icon
                  icon={Icons.CreditCardFront}
                  color={iconColor(ElementsEnum.CardNumber)}
                  size={14}
                  position="absolute"
                  top="40px"
                  left="15px"
                />
                <Label className="label">Card Number</Label>
                <CardNumberElement
                  className="Element"
                  options={{ style, placeholder: "Card Number" }}
                  onFocus={() => setFocus(ElementsEnum.CardNumber)}
                  onBlur={() => setFocus(null)}
                  onChange={(e) => {
                    setFieldErrors(ErrorKeyEnum.CardNumber, null);
                    if (e.complete) {
                      setBillingDetails({ ...billingDetails, cardNumber: e.empty });
                    } else {
                      setBillingDetails({ ...billingDetails, cardNumber: true });
                    }
                  }}
                />
              </ElementContainer>
            </Row>
            {fieldErrors[ErrorKeyEnum.CardNumber] && <ErrorText margin='5px 0px 10px 0px'>{fieldErrors[ErrorKeyEnum.CardNumber]}</ErrorText>}
            <Row>
              <CardCvcNumber>
                <ElementContainer focused={isFocused(ElementsEnum.CVC)}>
                  <Icon
                    icon={Icons.CalendarDayLight}
                    color={iconColor(ElementsEnum.CVC)}
                    size={14}
                    position="absolute"
                    top="40px"
                    left="15px"
                  />
                  <Label className="label">CVC</Label>
                  <CardCvcElement
                    className="Element"
                    options={{ style, placeholder: "CVC" }}
                    onFocus={() => setFocus(ElementsEnum.CVC)}
                    onBlur={() => setFocus(null)}
                    onChange={(e) => {
                      if (e.complete) {
                        setFieldErrors(ErrorKeyEnum.CVC, null);
                        setBillingDetails({ ...billingDetails, cvc: e.empty });
                      } else {
                        setBillingDetails({ ...billingDetails, cvc: true });
                      }
                    }}
                  />
                  {fieldErrors[ErrorKeyEnum.CVC] && <ErrorText margin='5px 0px 10px 0px'>{fieldErrors[ErrorKeyEnum.CVC]}</ErrorText>}
                </ElementContainer>
                <ElementContainer focused={isFocused(ElementsEnum.ExpDate)}>
                  <Icon
                    icon={Icons.CreditCardBack}
                    color={iconColor(ElementsEnum.ExpDate)}
                    size={14}
                    position="absolute"
                    top="40px"
                    left="15px"
                  />
                  <Label className="label">Card Expiry Date</Label>
                  <CardExpiryElement
                    className="Element"
                    options={{ style, placeholder: "Exp. Date" }}
                    onFocus={() => setFocus(ElementsEnum.ExpDate)}
                    onBlur={() => setFocus(null)}
                    onChange={(e) => {
                      if (e.complete) {
                        setFieldErrors(ErrorKeyEnum.ExpDate, null);
                        setBillingDetails({ ...billingDetails, expDate: e.empty });
                      } else {
                        setBillingDetails({ ...billingDetails, expDate: true });
                      }
                    }}
                  />
                  {fieldErrors[ErrorKeyEnum.ExpDate] && <ErrorText margin='5px 0px 10px 0px'>{fieldErrors[ErrorKeyEnum.ExpDate]}</ErrorText>}
                </ElementContainer>
              </CardCvcNumber>

            </Row>
            <Row>
              <FormGroup>
                <Input
                  header="Email"
                  id="email"
                  type="email"
                  placeholder="Email"
                  required
                  error={fieldErrors[ErrorKeyEnum.Email]}
                  value={billingDetails.email}
                  onChange={(e) => {
                    setFieldErrors(ErrorKeyEnum.Email, null);
                    setBillingDetails({ ...billingDetails, email: e.target.value });
                  }}
                />

                <Input
                  header="Address"
                  id="addressLine1"
                  type="text"
                  placeholder="Address"
                  error={fieldErrors[ErrorKeyEnum.AddressLine1]}
                  value={billingDetails.address_line1}
                  onChange={(e) => {
                    setFieldErrors(ErrorKeyEnum.AddressLine1, null);
                    setBillingDetails({ ...billingDetails, address_line1: e.target.value });
                  }}
                />

                <SubForm>
                  <Input
                    header="City"
                    id="city"
                    type="text"
                    placeholder="City"
                    error={fieldErrors[ErrorKeyEnum.City]}
                    value={billingDetails.address_city}
                    onChange={(e) => {
                      setFieldErrors(ErrorKeyEnum.City, null);
                      setBillingDetails({ ...billingDetails, address_city: e.target.value });
                    }}
                  />
                  <DropDown>
                    <Label className="label">State</Label>
                    <StyledSelect
                      top='8px'
                      value={selectedState}
                      onChange={({ label, value }: any) => {
                        setFieldErrors(ErrorKeyEnum.State, null);
                        setSelectedState({ label: label, value })
                        setBillingDetails({ ...billingDetails, address_state: label === "--Select State--" ? "" : value as string });
                      }}
                      placeholder="State"
                      options={statesArray}
                    />
                    <ErrorText margin='5px 0px 0px 0px'>{fieldErrors[ErrorKeyEnum.State]}</ErrorText>
                  </DropDown>
                </SubForm>
                <SubForm>
                  <Input
                    header="ZIP/Postal Code"
                    id="zip"
                    type="text"
                    placeholder="ZIP/Postal Code"
                    error={fieldErrors[ErrorKeyEnum.Zip]}
                    value={billingDetails.address_zip}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      const { value } = event.currentTarget;
                      setFieldErrors(ErrorKeyEnum.City, null);
                      setBillingDetails({ ...billingDetails, address_zip: value as string });
                    }}
                  />
                  <DropDown>
                    <Label className="label">Country</Label>
                    <StyledSelect
                      value={selectedCountry}
                      onChange={({ label, value }: any) => {
                        setFieldErrors(ErrorKeyEnum.Country, null);
                        setBillingDetails({ ...billingDetails, address_country: value });
                        setSelectedCountry({ label: label, value })
                      }}
                      placeholder="Country"
                      options={countryOptions}
                    />
                     <ErrorText margin='5px 0px 0px 0px'>{fieldErrors[ErrorKeyEnum.Country]}</ErrorText>
                  </DropDown>
                </SubForm>
              </FormGroup>
            </Row>

            <Button
              margin="20px 0px 0px 0px"
              text="Submit"
              loading={loading}
            />
            <Spacer />
          </Form>
        </RequiredInput>
      </Container>
    </>
  )
}