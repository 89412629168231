import { useSelector } from "react-redux";
import { matchPath, useNavigate } from "react-router-dom";
import { Roles } from "../models/enums/RolesEnum";

const routePaths = {
    LOGIN: "/login",
    REGISTER: "/register",
    RESET_PASSWORD: "/resetPassword",
    FORGOT_PASSWORD: "/forgotPassword",
    SET_PASSWORD: "/setPassword",
    STRIPE: "/stripe",
    ABOUT: "/about",
    VIDEOS: "/videos",
    BILLING: "/billing",
    HOME: "/home",
    DEVICES: "/devices",
    TESTS: "/tests",
    SUPER_ADMIN: "/superAdmin",
    VIEW_ACCOUNT: "/viewAccount",
    TEST_DETAIL: `/tests/:id`,
    ADD_USER: "/addUser",
    UPDATE_USER: "/updateUser",
    ACCOUNT_DETAIL: "/accountDetail",
    MY_PROFILE: "/myProfile",
    ADD_ACCOUNT: "/addAccount"
};
const SUPER_ADMINADMIN_ROUTES = Object.values(routePaths)
const ADMIN_ROUTES = SUPER_ADMINADMIN_ROUTES.filter(x => x !== routePaths.SUPER_ADMIN)
export const ROLES_PERMISSIONS = {
    [Roles.SuperAdmin]: SUPER_ADMINADMIN_ROUTES,
    [Roles.Admin]: ADMIN_ROUTES,
    [Roles.User]: [routePaths.LOGIN, routePaths.TESTS, routePaths.TEST_DETAIL, routePaths.MY_PROFILE],
    [Roles.Reader]: [routePaths.LOGIN, routePaths.TESTS, routePaths.TEST_DETAIL, routePaths.MY_PROFILE],
}
export const hasPermisison = (role, location) => {
    if (!role) return true;
    const allPermisisons = ROLES_PERMISSIONS[role] || [];
    const isPermitted = allPermisisons?.some((x) =>
        matchPath(x, location?.pathname)
    );
    return isPermitted;
};

export const navigateToRoleHome = (role = '', navigate) => {
    switch (role) {
        case Roles.SuperAdmin:
            navigate(routePaths.HOME)
            break;
        case Roles.Admin:
            navigate(routePaths.HOME)
            break;
        case Roles.User:
            navigate(routePaths.TESTS)
            break;
        case Roles.Reader:
            navigate(routePaths.TESTS)
            break;
        default:
            navigate(routePaths.LOGIN)
            break;
    }
}

export default routePaths;