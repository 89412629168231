export const successMessages = {
   LOGIN_SUCCESS: "Login Success.",
   DEVICE_ADD_SUCCESS: "Device added successfully.",
   DEVICE_UPDATE_SUCCESS: "Device updated successfully.",
   CREATE_USER_SUCCESS: "Invitation to the user has been sent successfully.",
   USER_UPDATE_SUCCESS: "User updated successfully",
   TEST_UPDATE_SUCCESS: "Test updated successfully",
   RESEND_CODE_SUCCESS: "Verification code has been sent successfully.",
   EAMIL_VERIFIED_SUCCESS: "Email verified successfully. Please login to your account!",
   PASSWORD_CHANGED_SUCCESS: "Password has been changed successfully.",
   STRIPE_SUCCESS: "Your subscription has been created successfully.",
   STRIPE_UPDATE: "Your card has been updated successfully.",
   VERIFICATION_CODE_SENT: "Your verification code has been sent to your registered email.",
   CANCEL_SUBSCRIPTION: "Your subscription has been canceled successfully.",
   LOGIN_SUCCESS_To_STRIPE: "Login Success. Please add your account details.",
   ORGANIZATION_ADD_SUCCESS: "Organization added successfully.",
   ORGANIZATION_UPDATE_SUCCESS: "Organization updated successfully.",
   STRIPE_PAYMENT_UPDATE_SUCCESS: "Payment details updated successfully.",
   EMAIL_RESEND: "We have sent an email to the specified email address.",
   RESEND_INVITATION: "Invitation resent successfully",
};

export const errorMessages = {
   PASSWORD_NOT_MATCHED: "Password and Confirm Password did't match.",
   API_NOT_AVAILABLE: "The Gaize system is having issues processing requests. Please login again.",
   EMAIL_NOT_CONFIRMED: "User is not confirmed",
   WRONG_PATH: "Role is not defined.",
   INACTIVE_USER: "Username or Password is incorrect",
   DISABLE_LOGIN: "Username or Password is incorrect",
   USER_NOT_FOUND: "Username or Password is incorrect",
   WRONG_PASSWORD: "Username or Password is incorrect",
   OLD_WRONG_PASSWORD: "Your old password is incorrect.",
   SOMETHING_WENT_WRONG: "We were unable to change your password."
};
