import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colors } from "../../util/Colors";
import { faCheckCircle as CheckCircleSolid } from "@fortawesome/free-solid-svg-icons";
import { faHistory as HistorySolid } from "@fortawesome/free-solid-svg-icons";
import { faLink as LinkSolid } from "@fortawesome/free-solid-svg-icons";
import { faBars as MenuSolid } from "@fortawesome/free-solid-svg-icons";
import { faMobileAlt as MobileSolid } from "@fortawesome/free-solid-svg-icons";
import { faEye as Eye } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle as TimesCircleSolid } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle as Warning } from "@fortawesome/free-solid-svg-icons";
import { faFolder as Folder } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard as CreditCardFront } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard as CreditCardBack } from "@fortawesome/free-solid-svg-icons";
import { faCalendar as calendar } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight as RightArrow } from "@fortawesome/free-solid-svg-icons";
import { faHome as Home } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft as Back } from '@fortawesome/free-solid-svg-icons';
import { faSearch as SearchRegular } from "@fortawesome/free-solid-svg-icons";
import { faEdit as Edit } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt as Pen } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt as SignOut } from '@fortawesome/free-solid-svg-icons';
import { faVideo as Video } from '@fortawesome/free-solid-svg-icons';
import { faUser as User } from '@fortawesome/free-solid-svg-icons';
import { faDollarSign as DollarSign } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle as UserCircle } from '@fortawesome/free-solid-svg-icons';
import { faWallet as Wallet } from '@fortawesome/free-solid-svg-icons';
import { faTimes as Cancel } from "@fortawesome/free-solid-svg-icons";
import { faMapMarker as LocationArrow } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft as BackArrow } from "@fortawesome/free-solid-svg-icons";
import { faArrowUp as UpArrow } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDay as CalendarDayLight } from "@fortawesome/free-solid-svg-icons";
import { faCopy as Copy } from "@fortawesome/free-solid-svg-icons";
import { faSort as Sort } from "@fortawesome/free-solid-svg-icons";

export const Icons = {
  CheckCircleSolid,
  HistorySolid,
  LinkSolid,
  MenuSolid,
  MobileSolid,
  Eye,
  TimesCircleSolid,
  Warning,
  Folder,
  CreditCardFront,
  CreditCardBack,
  calendar,
  RightArrow,
  Home,
  Back,
  SearchRegular,
  Edit,
  Pen,
  SignOut,
  Video,
  DollarSign,
  User,
  UserCircle,
  Wallet,
  Cancel,
  LocationArrow,
  BackArrow,
  UpArrow,
  CalendarDayLight,
  Copy,
  Sort
};

const Container = styled.span<any>`
  color: ${(props) => props.color};
  &:hover {
    color: ${(props) => {
    if (props.color === "inherit") return null;
    return props.hoverColor;
  }};
  }
`;

export type IconProps = {
  icon: any;
  color?: Colors | string | null;
  hoverColor?: Colors | string | null;
  onClick?: any | null;
  size?: string | number | undefined;
  position?: string;
  top?: string | number | undefined;
  left?: string | number | undefined;
  right?: string | number | undefined;
  zIndex?: number | void;
  margin?: string | number | undefined;
  tip?: string;
  transitionDuration?: string;
  rotation?: number;
};

export default function Icon({
  icon = Icons.Home,
  color = Colors.grey,
  hoverColor = null,
  onClick,
  size = 20,
  top,
  left,
  right,
  position = "relative" as any,
  zIndex,
  margin,
  tip,
  transitionDuration,
  rotation,
}: IconProps) {
  const cursor: string = onClick ? "pointer" : "";

  return (
    <Container color={color} hoverColor={hoverColor} data-tip={tip}>
      <FontAwesomeIcon
        icon={icon as any}
        onClick={onClick}
        style={{
          top,
          left,
          right,
          position: position as any,
          zIndex: zIndex as any,
          fontSize: size,
          transition: `all ${transitionDuration || "0.2s"}`,
          transform: rotation ? `rotate(${rotation}deg)` : undefined,
          margin,
          cursor,
        }}
      />
    </Container>
  );
}
