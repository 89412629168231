/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import { Colors } from "../../util/Colors";
import Icon, { Icons } from "../elements/Icon";
import UpdateCardInfo from "./UpdateCardInfoModal";
import "reactjs-popup/dist/index.css";
import DeactivateAccount from "./DeactivateAccountModal";
import UpdateDevice from "./UpdateDeviceModal";
import AddNewDevice from "./AddNewDevice";
import TestMapModal from "./TestsMapModal";
import StripeDetailsModal from "./StripeDetailsModal";
import { colors } from "react-select/dist/declarations/src/theme";
import ForgotPassword from "./ForgotPasswordModal";
import { Toaster } from "react-hot-toast";
import DeviceConfirmation from "./deviceConfirmation";

const modalStyle = {
   display: "flex",
   flexDirection: "column",
   background: "white",
   borderRadius: "5px",
   width: "500px",
   padding: "0px",
};

const Title = styled.div`
   font-weight: 800;
   font-size: 1.4rem;
`;

const Header = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 15px 20px;
   border-bottom: 1px solid ${Colors.grey6};
   position: sticky;
   top: 0;
   z-index: 99;
   background-color: ${Colors.white};
`;

export const ModalList = Object.freeze({
   UpdateCardInfo: "Update card details",
   DeactivateAccount: "Deactivate Account",
   UpdateDevice: "Update Device",
   AddNewDevice: "Add New Device",
   DeviceConfirmation: "Device Confirmation",
   TestMapModal: "Test Detail",
   UserModal: "Update User",
   StripeDetailsModal: "Stripe Details",
   ForgotPassword: "Forgot Password",
   ChangePassword: "Change Password",
});

export default function BaseModal({ open, modalType, onClose, ...rest }): any {
   if (open) {
      document.body.classList.add("touchMode");
   } else {
      document.body.classList.remove("touchMode");
   }
   const renderModal = (modalType: any, close: any) => {
      switch (modalType) {
         case ModalList.UpdateCardInfo:
            return <UpdateCardInfo close={close} {...rest} />;
         case ModalList.DeactivateAccount:
            return <DeactivateAccount close={close} {...rest} />;
         case ModalList.UpdateDevice:
            return <UpdateDevice close={close} deviceDetail {...rest} />;
         case ModalList.AddNewDevice:
            return <AddNewDevice close={close} {...rest} />;
         case ModalList.TestMapModal:
            return <TestMapModal close={close} testDetail {...rest} />;
         case ModalList.StripeDetailsModal:
            return <StripeDetailsModal close={close} {...rest} />;
         case ModalList.ForgotPassword:
            return <ForgotPassword close={close} {...rest} />;
         case ModalList.ChangePassword:
            return <ForgotPassword close={close} {...rest} />;
         case ModalList.DeviceConfirmation:
            return <DeviceConfirmation close={close} {...rest} />;
         default:
            return null;
      }
   };

   useEffect(() => {
      const HamBergerElement = document.getElementById("hamberger");

      if (HamBergerElement?.style) {
         if (open) {
            // set hamberger index to negitive
            HamBergerElement.style.zIndex = "1";
         } else {
            // set positive index
            HamBergerElement.style.zIndex = "20";
         }
      }
   }, [open, modalType]);

   return (
      <>
         <Popup
            modal
            open={open}
            closeOnDocumentClick={false}
            onClose={() => onClose(false)}
            contentStyle={modalStyle as any}
         >
            {(close) => (
               <>
                  <Header>
                     <Title>{modalType}</Title>
                     <Icon
                        icon={Icons.TimesCircleSolid}
                        onClick={() => {
                           document.body.classList.remove("touchMode");
                           close();
                        }}
                        size={15}
                     />
                  </Header>
                  {renderModal(modalType, close)}
               </>
            )}
         </Popup>
      </>
   );
}
