import React, { useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../util/Colors";
import Icon, { Icons } from "../components/elements/Icon";
import { useQuery } from "@apollo/client";
import Input from "../components/elements/Input";
const noImg = require("../assets/images/user.png");
const errorImg = require("../assets/images/error_msg.gif");
import GET_DEVICES from "../graphql/queries/getDevices.query";
import BaseModal, { ModalList } from "../components/modal/BaseModal";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { GaizeState } from "../redux/store";
import { ErrorImage } from "./SuperAdmin";
import LoaderContainer from "../components/elements/LoaderContainer";
import Loader, { LoaderSizes } from "../components/elements/Loader";
import { Roles } from "../models/enums/RolesEnum";
import moment from "moment";

export const MainWrapper = styled.div`
   padding: 40px 0;
   margin: 64px 0 0 0;
`;
export const Container = styled.div`
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   margin-right: auto;
   margin-left: auto;
   max-width: 1140px;
`;
export const SubHeader = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 0 0 30px 0;
   @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
   }
`;
const Heading = styled.div`
   font-weight: 700;
   font-size: 28px;
   line-height: 32px;
   letter-spacing: -0.165px;
   color: #302e2e;
   @media screen and (max-width: 768px) {
      margin: 0 0 10px 0;
      font-size: 24px;
   }
`;
export const Card = styled.div`
   margin: 0 0 50px 0;
   &:last-child {
      margin: 0;
   }
`;
export const CardHeader = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 0px 0 10px 0;
`;
export const LeftWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
`;
export const CardTitle = styled.div`
   font-weight: 700;
   font-size: 21px;
   line-height: 24px;
   letter-spacing: -0.165px;
   color: #302e2e;
   margin: 0 30px 0 0;
   display: flex;
   column-gap: 10px;
   align-items: center;
`;
export const CardSubTitle = styled.div`
   font-weight: 600;
   font-size: 15px;
   line-height: 24px;
   letter-spacing: -0.165px;
   color: #7a8ea5;
   cursor: pointer;
`;
export const CardBody = styled.div``;
export const TableResponsive = styled.div`
   display: block;
   width: 100%;
   overflow-x: auto;
   margin-bottom: 15px;
`;
export const CardTable = styled.div`
   width: 100%;
   margin-bottom: 0.4rem;
   border-collapse: collapse;
   display: table;
   text-indent: initial;
   border-spacing: 2px;
`;
export const CardTableThead = styled.div`
   display: table-header-group;
   vertical-align: middle;
   border-color: inherit;
   background: #f9f9f9;
`;
export const CardTr = styled.div`
   display: table-row;
   vertical-align: inherit;
   border-color: inherit;
   border-bottom: 3px solid #e5e5e5;
   box-shadow: 0px 1px 0px #f2f2f2;
   background: #ffffff;
`;
export const CardTh = styled.div`
   vertical-align: bottom;
   padding: 16px 10px 7px;
   display: table-cell;
   font-weight: 600;
   font-size: 13px;
   line-height: 24px;
   letter-spacing: -0.165px;
   text-transform: uppercase;
   color: #7a8ea5;
   /* min-width: 122px; */
   white-space: nowrap;
`;
export const CardThSticky = styled(CardTh)`
   position: sticky;
   right: 0;
   background-color: #fff;
`;
export const CardTableBody = styled.div`
   display: table-row-group;
   vertical-align: middle;
   border-color: inherit;
`;
export const CardTd = styled.div<{ activeHover?: boolean }>`
   padding: 16px 10px;
   vertical-align: top;
   display: table-cell;
   font-weight: 600;
   font-size: 15px;
   line-height: 24px;
   letter-spacing: -0.165px;
   color: #302e2e;
   /* min-width: 122px; */
   white-space: nowrap;
   position: relative;
   vertical-align: middle;
   .version {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      display: inline-block;
      margin: 0 14px 0 0;
      position: relative;
      top: 2px;
      &.greenColor {
         background-color: #00ba88;
      }
      &.orangeColor {
         background-color: #f2994a;
      }
   }
   .assignIcon {
      overflow: hidden;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin: 0 14px 0 0;
      position: relative;
      background-color: #f9f9f9;
      display: inline-block;
      vertical-align: middle;
   }
   .editIcon {
      position: absolute;
      right: 22px;
      top: 50%;
      transform: translateY(-50%);
   }
   ${[
      (props) =>
         props.activeHover &&
         `cursor: pointer;
   &: hover{
      & svg{
         color: ${Colors.blue} !important;
      }
      color: ${Colors.blue};
   }`,
   ]}
`;
export const CardTdSticky = styled(CardTd)`
   position: sticky;
   right: 0;
   background-color: #fff;
`;
export const MapWrapper = styled.div`
   width: 100%;
   height: calc(100vh - 253px);
`;
export const MapImage = styled.img`
   width: 100%;
`;
export const AssignImage = styled.img`
   width: 35px;
   height: 35px;
   object-fit: cover;
 `;

const DeviceUserName = styled.span`
   padding-right: 25px;
`;

const AssigneWrapper = styled.div`
   display: flex;
   align-items: center;
`;

const Search = styled.div`
   width: 350px;
   position: relative;
`;
const SearchWrapper = styled.div``;
const IconSearch = styled.div`
   background: linear-gradient(127.15deg, #386ee2 17.06%, #6f24bc 93.36%);
   border-radius: 4px;
   width: 42.26px;
   height: 40px;
   position: absolute;
   right: 0;
   top: 0;
   text-align: center;
   line-height: 40px;
`;

export const NoContentText = styled.div`
   font-size: 26px;
   font-weight: 600;
   color: #333333;
   border: 1px solid ${Colors.grey6};
   padding: 100px 15px;
   border-radius: 10px;
   text-align: center;
`;

const DeviceView = () => {
   const addedDevice = localStorage.getItem("deviceAdd");
   const updatedDevice = localStorage.getItem("deviceUpdate");
   // State
   const user = useSelector((state: GaizeState) => state.user as any);
   const userData = user?.organization;
   const userRole = userData?.role;
   const pathname = window.location.pathname;
   const [editDevice, setEditDevice] = React.useState(false);
   const [addDevice, setAddDevice] = React.useState(false);
   const [deviceDetail, setDeviceDetail] = React.useState("");
   const [devicesDetails, setDevicesDetails] = React.useState([]);
   const [search, setSearch] = React.useState("");
   const [searchDevices, setSearchDevices] = React.useState([]);
   const [isDataLoading, setIsDataLoading] = React.useState(true);

   const onEditDevice = (item) => {
      setEditDevice(true);
      setDeviceDetail(item);
   };

   /** GraphQL */
   const { data } = useQuery(GET_DEVICES, {
      fetchPolicy: "network-only",
      onCompleted(res) {
         setIsDataLoading(false);
         setDevicesDetails(res?.getDevices);
         setSearchDevices(res.getDevices);
      },
   });

   //  Actions
   useEffect(() => {
      if (search) {
         const serchLower = search.toLocaleLowerCase()?.trim();
         setSearchDevices(
            devicesDetails.filter((item: any) => {
               let deviceUserName =
                  item.user?.firstName || item.user?.firstName
                     ? `${item.user?.firstName}` +
                       " " +
                       `${item.user?.lastName}`
                     : " ";
               deviceUserName = deviceUserName.toLocaleLowerCase();
               if (
                  deviceUserName.includes(serchLower) ||
                  item.serialNumber?.toLocaleLowerCase().includes(serchLower) ||
                  item.assetId?.toLocaleLowerCase().includes(serchLower)
               ) {
                  return true;
               }
            })
         );
      } else {
         setSearchDevices(devicesDetails);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [search]);

   return (
      <>
         <BaseModal
            open={editDevice}
            modalType={ModalList.UpdateDevice}
            onClose={setEditDevice}
            deviceDetail={deviceDetail}
         />
         <BaseModal
            open={addDevice}
            modalType={ModalList.AddNewDevice}
            onClose={setAddDevice}
         />
         {pathname === "/devices" && userRole !== Roles.User ? (
            <MainWrapper>
               <Container>
                  <Card>
                     <CardHeader>
                        <LeftWrapper>
                           <CardTitle>
                              Devices{" "}
                              {userData.role === Roles.SuperAdmin && (
                                 <CardSubTitle
                                    onClick={() => setAddDevice(true)}
                                 >
                                    Add New Device
                                 </CardSubTitle>
                              )}{" "}
                           </CardTitle>
                           <Search>
                              <SearchWrapper>
                                 <Input
                                    type="text"
                                    placeholder="Search (Serial Number, Asset Tag, Assignee)"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    required
                                    width="100%"
                                 />
                              </SearchWrapper>
                              <IconSearch>
                                 <Icon
                                    icon={Icons.SearchRegular}
                                    size={14}
                                    color={Colors.white}
                                 />
                              </IconSearch>
                           </Search>
                        </LeftWrapper>
                     </CardHeader>
                     <CardBody>
                        <TableResponsive>
                           <CardTable>
                              <CardTableThead>
                                 <CardTr>
                                    <CardTh>Serial Number #</CardTh>
                                    <CardTh>Model</CardTh>
                                    <CardTh>Software Version</CardTh>
                                    <CardTh>Asset Tag</CardTh>
                                    <CardTh>Last used</CardTh>
                                    <CardTh>Status</CardTh>
                                    <CardTh>Assignee</CardTh>
                                 </CardTr>
                              </CardTableThead>
                              <CardTableBody>
                                 {searchDevices &&
                                    searchDevices.map((item: any, i) => {
                                       const deviceUserName =
                                          item.user?.firstName ||
                                          item.user?.firstName
                                             ? `${item.user?.firstName}` +
                                               " " +
                                               `${item.user?.lastName}`
                                             : "";
                                       const deviceUserImage =
                                          item.user?.photoUrl &&
                                          item.user?.photoUrl;

                                       return (
                                          <CardTr key={i}>
                                             <CardTd>
                                                {item.serialNumber}
                                             </CardTd>
                                             <CardTd>
                                                {item.deviceModel?.name}
                                             </CardTd>
                                             <CardTd>
                                                {" "}
                                                {item.softwareVersion && (
                                                   <span className="version greenColor"></span>
                                                )}
                                                {item.softwareVersion}
                                             </CardTd>
                                             <CardTd>{item.assetId}</CardTd>
                                             <CardTd>
                                                {item?.lastUsedAt &&
                                                   moment(
                                                      parseInt(
                                                         item?.lastUsedAt,
                                                         10
                                                      )
                                                   ).format(
                                                      "MMM DD, YYYY (h:mm A)"
                                                   )}
                                             </CardTd>
                                             <CardTd>
                                                {item?.active?"Active":"Inactive"}
                                             </CardTd>
                                             <CardTd>
                                                <AssigneWrapper>
                                                   {deviceUserName && (
                                                      <span className="assignIcon">
                                                         <AssignImage
                                                            src={
                                                               deviceUserImage ||
                                                               noImg
                                                            }
                                                         />
                                                      </span>
                                                   )}
                                                   <DeviceUserName>
                                                      {deviceUserName}
                                                   </DeviceUserName>
                                                   <span className="editIcon">
                                                      <Icon
                                                         icon={Icons.Pen}
                                                         size={16}
                                                         color={Colors.black}
                                                         onClick={() =>
                                                            onEditDevice(item)
                                                         }
                                                      />
                                                   </span>
                                                </AssigneWrapper>
                                             </CardTd>
                                          </CardTr>
                                       );
                                    })}
                              </CardTableBody>
                           </CardTable>
                           {isDataLoading ? (
                              <LoaderContainer>
                                 <Loader
                                    color={Colors.blue}
                                    size={LoaderSizes.Medium}
                                 />
                              </LoaderContainer>
                           ) : (
                              searchDevices?.length === 0 && (
                                 <NoContentText>
                                    No Records Found{" "}
                                 </NoContentText>
                              )
                           )}
                        </TableResponsive>
                     </CardBody>
                  </Card>
               </Container>
            </MainWrapper>
         ) : (
            <ErrorImage src={errorImg} />
         )}
      </>
   );
};
export default DeviceView;
