import gql from 'graphql-tag';

const query = gql`
query getAllTests($startDate: String, $endDate: String){
    getAllTests(startDate:$startDate,endDate:$endDate ){
            _id
            organizationId
            userId
            deviceId
            testNumber
            status
            caseNumber
            timings{
                startTime
                endTime
            }
            location{
                geoLatitude
                geoLongitude
            }
            files{
                jsonUrl
                ocumenUrl
                videoUrl
                testLog
                subtitleVideoUrl
                webVideoUrl
                zipFileUrl
            }    
            meta{
          createdDate
          createdBy
          modifiedBy
          modifiedDate
      }
      user{
          _id      
          organizationId
          firstName
          lastName
          firebaseId
          title
          email
          phoneNumber
          phoneNumberExt
          photoUrl
          role
          active
          passwordResetCode
          meta{
              createdBy
              createdDate
              modifiedBy
              modifiedDate
          }           }
          organization{
                     _id
      name
   ownerFirstName
   ownerLastName
   ownerTitle
   ownerPhone
   ownerEmail
   address{
       addressLine1
       addressLine2
       city
       state
       country
   }
   active
   billing{
    planId
    status
    stripeCustomerId
    paidThrough
    nextPaymentDate
    paymentMethodDesc
   }
   payments{
       stripeChargeId
       paidDateTime
       amount
       taskId
   }
          }
          device{
                      _id
      organizationId
      serialNumber
      pairingCode
      active
      deviceModelId
      softwareVersion
      assetId
      assignedUserId,
      lastUsedAt
          }          
  }
}
`;

export default query;