import gql from 'graphql-tag';

const query = gql`
query getPlans{
    getPlans{
            _id 
            name
  }
}
`;
export default query;