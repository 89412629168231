import React, { useEffect } from "react";
import Joi from "joi";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";
import Button from "../elements/button/Button";
import Input from "../../components/elements/Input";
import StyledSelect from "../elements/DropDown";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import GET_DEVICE from "../../graphql/queries/getDevice.query";
import GET_USERS from "../../graphql/queries/getUsers.query";
import GET_ALL_DEVICES_MODEL from "../../graphql/queries/getDeviceModels.query";
import GET_SERIAL_NUMBER from "../../graphql/queries/getDeviceBySerialNumber.query";
import UPDATE_DEVICE from "../../graphql/mutations/updateDevice.mutation";
import GET_DEVICES from "../../graphql/queries/getDevices.query";
import ErrorText from "../../components/elements/ErrorText";
import * as ErrorUtil from "../../util/ErrorUtil";
import * as Schema from "../../util/Schema";
import makeEventHandler from "../../util/MakeEventHandler";
import { useSelector } from "react-redux";
import { GaizeState } from "../../redux/store";
import { Roles } from "../../models/enums/RolesEnum";
import { RequiredInput } from "../../pages/AddAccount";
import { successMessages } from "../../config/messages.config";
import BaseModal, { ModalList } from "./BaseModal";

const schema = Joi.object({
   serialNumber: Schema.devices.serialNumber().error(([error]) => {
      const message = "Serial number is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),
   model: Schema.devices.model().error(([error]) => {
      const message = "Model is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),
});

const Container = styled.div`
   padding: 20px 15px;
`;
const Form = styled.div``;
const Spacer = styled.div`
   margin: 10px;
`;
const ButtonContainer = styled.div`
   margin-top: 20px;
   display: flex;
   justify-content: center;
   & Button:first-child {
      margin-right: 10px;
   }
`;

enum ErrorKeyEnum {
   SerialNumber = "serialNumber",
   Model = "model",
}

export default function UpdateDevice({ close, deviceDetail }) {
   const user = useSelector((state: GaizeState) => state.user as any);
   const userData = user?.organization;
   const [serialNumber, setSerialNumber] = React.useState("");
   const [assetTag, setAssetTag] = React.useState("");
   const [assignee, setAssignee] = React.useState("");
   const [model, setModel] = React.useState("");
   const [isSuccess, setIsSuccess] = React.useState(false);
   const [loading, setLoading] = React.useState(false);
   const [openNotificationModal, setOpenNotificationModal] =
      React.useState(false);
   const [error, setError] = React.useState("");

   const [fieldErrors, setFieldErrorsInternal] = React.useState({
      [ErrorKeyEnum.Model]: null,
      [ErrorKeyEnum.SerialNumber]: null,
   });

   const eventHandler = makeEventHandler(() => setError(""));

   const setFieldErrors = (field: string, message: string | null) => {
      const newFieldErrors: any = {
         [field]: message,
      };
      setFieldErrorsInternal(newFieldErrors);
   };

   const onChangeAssetTag = eventHandler((value: string) => {
      setAssetTag(value);
   });

   type selectedStateType = {
      value: string;
      label: string;
   };

   const [selectedModel, setSelectedModel] =
      React.useState<selectedStateType | null>(() => {
         return null;
      });
   const [selectedAssignee, setSelectedAssignee] =
      React.useState<selectedStateType | null>(() => {
         return null;
      });

   useEffect(() => {
      if (selectedModel) {
         setModel(selectedModel?.value as any);
      }
      if (selectedAssignee) {
         setAssignee(selectedAssignee?.value as any);
      }
      if (
         selectedAssignee?.value === undefined &&
         selectedAssignee?.label === undefined
      ) {
         setSelectedAssignee(null);
      }
   }, [selectedModel, selectedAssignee]); //eslint-disable-line

   /* GraphQL */
   const { data: deviceData } = useQuery(GET_DEVICE, {
      variables: {
         deviceId: deviceDetail?._id,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
         const deviveData = data?.device;
         if (deviveData) {
            const fName = deviveData?.user?.firstName
               ? deviveData?.user?.firstName
               : undefined;
            const lName = deviveData?.user?.lastName
               ? deviveData?.user?.lastName
               : undefined;
            const assigneeUser = `${fName}` + " " + `${lName}`;
            setSelectedAssignee({
               value: deviveData?.assignedUserId
                  ? deviveData?.assignedUserId
                  : null,
               label:
                  assigneeUser === "undefined undefined"
                     ? "--Select Assignee--"
                     : assigneeUser,
            });
            setSerialNumber(deviveData?.serialNumber);
            setSelectedModel({
               value: deviveData?.deviceModel?._id,
               label: deviveData?.deviceModel?.name,
            });
            setAssetTag(deviveData?.assetId);
         }
      },
   });

   // Get User Data
   const { data } = useQuery(GET_USERS, { fetchPolicy: "network-only" });
   const activeAssignee = data?.getUsers?.filter(
      (data: any) => data.active && data.role !== Roles.SuperAdmin
   );
   const assigneeOptions =
      activeAssignee &&
      activeAssignee.map((data, i) => {
         const assigneeUser = `${data.firstName}` + " " + `${data.lastName}`;
         return {
            label: assigneeUser ? assigneeUser : null,
            value: data._id,
         };
      });
   assigneeOptions &&
      assigneeOptions.splice(0, 0, { label: "--Select Assignee--", value: "" });

   // Get Model Data
   const { data: devicesModel } = useQuery(GET_ALL_DEVICES_MODEL);
   const modelOptions = devicesModel?.getDeviceModels.map((data, i) => {
      return {
         label: data.name,
         value: data._id,
      };
   });

   //Serial Number

   const [getSerialNumber, { data: serialData }] = useLazyQuery<any>(
      GET_SERIAL_NUMBER,
      {
         variables: {
            serialNumber: serialNumber,
         },
         fetchPolicy: "network-only",
         onCompleted: (data: any) => {
            if (
               data?.getDeviceBySerialNumber?.organizationId ==
               userData?.organizationId
            ) {
               toast.error("Already exists");
               setLoading(false);
            } else {
               setOpenNotificationModal(true);
            }
         },
         onError: () => {
            updateDeviceMutation({
               variables: {
                  params: {
                     _id: deviceDetail?._id,
                     serialNumber: serialNumber,
                     deviceModelId: model,
                     assetId: assetTag,
                     assignedUserId: assignee,
                  },
               },
            });
         },
      }
   );

   // Update Device
   const [updateDeviceMutation] = useMutation(UPDATE_DEVICE, {
      refetchQueries: [
         {
            query: GET_DEVICES,
         },
      ],
      onCompleted: async (data) => {
         setIsSuccess(false);
         toast.success(successMessages.DEVICE_UPDATE_SUCCESS);
         localStorage.setItem("deviceUpdate", "true");
         setLoading(false);
         close();
      },
      onError: async (error) => {
         const errorMsg = ErrorUtil.getErrorMessage(error);
         setError(errorMsg);
         if (error) {
            toast.error(errorMsg);
         }
         setLoading(false);
      },
   });

   const updateDeviceData = (event?: React.FormEvent<HTMLFormElement>) => {
      if (event) {
         event.preventDefault();
      }

      const params = schema.validate({
         model,
         serialNumber,
      });

      const { error: schemaError } = params;
      if (schemaError) {
         const { field, message } = JSON.parse(schemaError.message);
         setFieldErrors(field, message);
         return;
      }
      setError("");
      toast.remove();
      if (serialNumber === deviceDetail?.serialNumber && deviceDetail?.active) {
         updateDeviceMutation({
            variables: {
               params: {
                  _id: deviceDetail?._id,
                  serialNumber: serialNumber,
                  deviceModelId: model,
                  assetId: assetTag,
                  assignedUserId: assignee,
               },
            },
         });
      } else {
         getSerialNumber({ variables: { serialNumber: serialNumber } });
      }
      setLoading(false);
   };

   useEffect(() => {
      if (isSuccess) {
         updateDeviceMutation({
            variables: {
               params: {
                  _id: deviceDetail?._id,
                  serialNumber: serialNumber,
                  deviceModelId: model,
                  assetId: assetTag,
                  assignedUserId: assignee,
               },
            },
         });
      }
   }, [isSuccess]); // eslint-disable-line

   return (
      <>
         <Container>
            <Form>
               <StyledSelect
                  header="Assignee"
                  placeholder="Assignee"
                  options={assigneeOptions}
                  onChange={({ value, label }: any) => {
                     setSelectedAssignee({ label: label, value });
                  }}
                  value={selectedAssignee}
                  width="100%"
               />
               <Spacer />
               {userData.role === Roles.SuperAdmin && (
                  <>
                     <RequiredInput>
                        <Input
                           type="text"
                           header="Serial Number"
                           placeholder="Serial Number"
                           value={serialNumber}
                           onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                           ) => {
                              setFieldErrors(ErrorKeyEnum.SerialNumber, null);
                              setSerialNumber(e.target.value);
                           }}
                           required
                           error={fieldErrors[ErrorKeyEnum.SerialNumber]}
                           top="10px"
                           width="100%"
                        />
                        <Spacer />
                        <StyledSelect
                           header="Model"
                           placeholder="Model"
                           options={modelOptions}
                           value={selectedModel}
                           onChange={({ value, label }: any) => {
                              setFieldErrors(ErrorKeyEnum.Model, null);
                              setSelectedModel({ label: label, value });
                           }}
                           width="100%"
                        />
                     </RequiredInput>
                     {!selectedModel && (
                        <ErrorText margin="0 0 0 0">
                           {fieldErrors[ErrorKeyEnum.Model]}
                        </ErrorText>
                     )}
                     <Spacer />
                  </>
               )}
               <Input
                  type="text"
                  header="Asset Tag"
                  placeholder="Asset Tag"
                  value={assetTag}
                  onChange={onChangeAssetTag}
                  top="10px"
                  width="100%"
               />
            </Form>
            <ButtonContainer>
               <Button
                  margin="20px 0px 0px 0px"
                  text="Update"
                  loading={loading}
                  disabled={loading}
                  onClick={() => updateDeviceData()}
               />
               <Button
                  margin="20px 0px 0px 0px"
                  text="Cancel"
                  width="100%"
                  onClick={() => close()}
               />
            </ButtonContainer>
            <BaseModal
               open={openNotificationModal}
               modalType={ModalList.DeviceConfirmation}
               onClose={setOpenNotificationModal}
               setIsSuccess={setIsSuccess}
               userData={userData}
               setLoading={setLoading}
               organization={serialData?.getDeviceBySerialNumber?.organization}
            />
         </Container>
      </>
   );
}
