import styled from "styled-components";
import Button from "../elements/button/Button";

const Container = styled.div`
   padding: 20px 15px;
`;

const ButtonContainer = styled.div`
   margin-top: 20px;
   display: flex;
   justify-content: center;
   & Button:first-child {
      margin-right: 10px;
   }
`;

const DeviceConfirmation = ({ close, ...rest }) => {
   const handleSave = () => {
      rest.setIsSuccess(true);
      close();
   };
   return (
      <Container>
         This device already belongs to {rest.organization?.name}. Are you sure
         you would like to move it to {rest.userData?.organization?.name}?
         <ButtonContainer>
            <Button
               margin="20px 0px 0px 0px"
               text="Yes"
               onClick={handleSave}
               width="100%"
            />
            <Button
               margin="20px 0px 0px 0px"
               text="No"
               width="100%"
               onClick={() => {
                  rest.setLoading(false);
                  close();
               }}
            />
         </ButtonContainer>
      </Container>
   );
};

export default DeviceConfirmation;
