import gql from 'graphql-tag';

const mutation = gql`
mutation updatePaymentMethod($params: StripeCustomerInput!){
    updatePaymentMethod(params: $params){
        status
    }
}
`;

export default mutation;
