import gql from 'graphql-tag';

const mutation = gql`

mutation changeUserPassword($params: ChangeUserPassword!){
    changeUserPassword(params:$params){
        status
    }
}
    `;
export default mutation;