import React from "react";
import styled from "styled-components";
import { Colors } from "../../util/Colors";

type ErrorTextProps = {
  large?: boolean;
  align?: string;
  margin?: string;
}

const ErrorText = styled.div<ErrorTextProps>`
  color: ${Colors.red};
  font-size: ${(props) => (props.large ? "1.6rem" : "1.0rem")};
  font-weight: 600;
  margin: ${(props) => props.margin || "20px 0"};
  text-align: ${(props) => props.align || ""};
`;

export default ErrorText;
