import React from "react";
import styled from "styled-components";
import { Colors } from '../../util/Colors';
import * as Polished from 'polished';
import Select from 'react-select';
import ErrorText from "./ErrorText";

type StyledSelectProps = {
    margin?: string;
    padding?: string;
    width: string;
    error?: boolean;
    icon?: any;
    isDisabled?: boolean;
  }

  const Container = styled.div`
  position: relative;
  width:100%
`;

const StyledSelect = styled(Select)<StyledSelectProps>`
  /* background-color: ${Colors.blue}; */
  color: ${Colors.grey3};
  outline: none;
  border: 0px;
  border-radius: 10px;
  height: 40px;
  width: ${props => props.width};
  font-size: 14px;
  font-weight: 700;
  transition: all 0.2s;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  border: ${props => props.error && `1px solid ${Colors.red}`};

  &:hover {
    //  background-color: ${Polished.darken(0.01, Colors.blue)}; */
    //  border: ${props =>
    props.error
      ? `1px solid ${Colors.red}`
      : `1px solid ${Polished.darken(0.01, Colors.blue)}`}; */
  }

  &:focus {
  //  background-color: ${Polished.darken(0.02, Colors.blue)}; */
  //  border: ${props =>
    props.error
      ? `1px solid ${Colors.red}`
      : `1px solid ${Polished.darken(0.02, Colors.blue)}`}; */
  }
    @media (max-width:767px)
    {
      width:calc(100% - ${props => props.icon?"55px":"0px"});
    }
`;
const HeaderStyle = styled.div<any>`
   font-size: 16px;
   font-weight:bold;
   color: ${Colors.black};
   margin: ${(props: SelectProps) =>
      props.top ? "0px 0px 8px 0px" : "20px 0px 8px 0px"};
`;

const colourStyles = {
  menu: (base:any) => ({
    ...base,
    border: "1px solid #e0e0e0",
    height: "auto",
    position: "absolute",
    top: "2px"
}),
menuPortal: (base: any) => ({ // to popout dropdown options outside overflow
  ...base, 
  zIndex: 9999 
}) 
};

export type SelectProps = {
    placeholder?: string;
    value: any;
    defaultValue?: string;
    onChange?: any;
    options?: any;
    margin?: string;
    padding?: string;
    width?: string;
    error?: string | null;
    header?: string;
    top?:string;
    isDisabled?: boolean; 
  };

    export default function Dropdown({
        placeholder,
        value,
        defaultValue,
        onChange,
        options,
        margin,
        padding,
        header,
        width = 'fill-available',
        error,
        isDisabled,
      }: SelectProps) {
        return (
          <Container>  
            {header && <HeaderStyle className="label" top={top}>{header}</HeaderStyle>}
            <StyledSelect
              styles={colourStyles}
              placeholder={placeholder}
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              options={options}
              margin={margin}
              padding={padding}
              width={width}
              isDisabled={isDisabled}
              error={Boolean(error)}
              menuPortalTarget={document.body}
              maxMenuHeight={150}
              />
              {error && <ErrorText margin= "5px 0">{error}</ErrorText>}
          </Container>
        );
      }