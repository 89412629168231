import Joi from "joi";
// User
const mailformat = /^[a-zA-Z0-9-.+]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// eslint-disable-next-line
const validPassword = /^(?=.*[0-9])(?=.*[!"#$%&’()*+,-.\/:;<=>?@[\]^_`{|}~]).{8,}$/;

export const user = {
    email: () => Joi.string().email({ tlds: { allow: false } }),
    password: () => Joi.string().min(8).regex(validPassword).required(),
    confirmPassword: () => Joi.string().required().valid(Joi.ref('password'))
}

// Organization
// TODO: improve the validation (ex. phone number can only consist of numbers and cannot exceed a certain length)

export const devices = {
    serialNumber: () => Joi.string().required(),
    model: () => Joi.string().required(),
}

export const organization = {
    name: () => Joi.string().required(),
    title: () => Joi.string().optional().allow(""),
    plan: () => Joi.string().required(),
    paymentMethod: () => Joi.string().required(),
    firstName: () => Joi.string().required(),
    lastName: () => Joi.string().required(),
    email: () => Joi.string().regex(mailformat).email({ tlds: { allow: false } }).required(),
    phoneNumber: () => Joi.string().min(10).required(),
    phoneExtension: () => Joi.string().min(3).optional().allow("").allow(null),
    addressLine1: () => Joi.string().required(),
    role: () => Joi.string().required(),
}

export const stripe = {
    name: () => Joi.string().required(),
    cardNumber: () => Joi.boolean().invalid(true),
    cvc: () => Joi.boolean().invalid(true),
    expDate: () => Joi.boolean().invalid(true),
    email: () => Joi.string().regex(mailformat).email({ tlds: { allow: false } }).required(),
    addressLine1: () => Joi.string().required(),
    city: () => Joi.string().required(),
    state: () => Joi.string().required(),
    zip: () => Joi.string().required(),
    country: () => Joi.string().required(),
}