export enum USstates {
    State = "--Select State--",
    Alabama = 'Alabama',
    Alaska = 'Alaska',
    Arizona = 'Arizona',
    Arkansas = 'Arkansas',
    California = 'California',
    Colorado = 'Colorado',
    Connecticut = 'Connecticut',
    Delaware = 'Delaware',
    Florida = 'Florida',
    Georgia = 'Georgia',
    Hawaii = 'Hawaii',
    Idaho = 'Idaho',
    Illinois = 'Illinois',
    Indiana = 'Indiana',
    Iowa = 'Iowa',
    Kansas = 'Kansas',
    Kentucky = 'Kentucky',
    Louisiana = 'Louisiana',
    Maine = 'Maine',
    Maryland = 'Maryland',
    Massachusetts = 'Massachusetts',
    Michigan = 'Michigan',
    Minnesota = 'Minnesota',
    Mississippi = 'Mississippi',
    Missouri = 'Missouri',
    Montana = 'Montana',
    Nebraska = 'Nebraska',
    Nevada = 'Nevada',
    NewHampshire = 'New Hampshire',
    NewJersey = 'New Jersey',
    NewMexico = 'New Mexico',
    NewYork = 'New York',
    NorthCarolina = 'North Carolina',
    NorthDakota = 'North Dakota',
    Ohio = 'Ohio',
    Oklahoma = 'Oklahoma',
    Oregon = 'Oregon',
    Pennsylvania = 'Pennsylvania',
    RhodeIsland = 'Rhode Island',
    SouthCarolina = 'South Carolina',
    SouthDakota = 'South Dakota',
    Tennessee = 'Tennessee',
    Texas = 'Texas',
    Utah = 'Utah',
    Vermont = 'Vermont',
    Virginia = 'Virginia',
    Washington = 'Washington',
    WashingtonDC = 'Washington, D.C.',
    WestVirginia = ' West Virginia',
    Wisconsin = 'Wisconsin',
    Wyoming = 'Wyoming',
    Alberta = 'Alberta',
    BritishColumbia = 'British Columbia',
    Manitoba = 'Manitoba',
    NewBrunswick = 'New Brunswick',
    NewfoundlandandLabrador = 'Newfoundland and Labrador',
    NorthwestTerritories = 'Northwest Territories',
    NovaScotia = 'Nova Scotia',
    Nunavut = 'Nunavut',
    Ontario = 'Ontario',
    PrinceEdwardIsland = 'Prince Edward Island',
    Quebec = 'Quebec',
    Saskatchewan = 'Saskatchewan',
    Yukon = 'Yukon'
}
