import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import logger from "redux-logger";
import userReducer from "./user/user.reducer";
import appReducer  from "./app/app.reducer";

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
});

export type GaizeState = ReturnType<typeof rootReducer>;

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(logger),
    // (window as any).devToolsExtension && (window as any).devToolsExtension()
  )
);
export default store;
