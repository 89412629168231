import gql from 'graphql-tag';

const query = gql`
query plan($planId: String){
    plan(planId:$planId){
            _id 
            name
            meta{
                createdDate
                createdBy
                modifiedBy
                modifiedDate
            }
  }
}
`;
export default query;