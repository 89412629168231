import styled from 'styled-components';
import { Colors } from '../../util/Colors';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { GaizeState } from '../../redux/store';

const Container = styled.div`
  padding: 20px 15px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const Key = styled.div`
ont-size: 20px;
font-weight: 700;
color: ${Colors.black};
margin-right: 20px;
`;

const Value = styled.div`
ont-size: 20px;
font-weight: 700;
color: ${Colors.grey3};
`;


export default function TestMapModal({ close, testDetail }) {
    const deviceUserName = testDetail.user?.firstName || testDetail.user?.firstName ? `${testDetail.user?.firstName}` + " " + `${testDetail.user?.lastName}` : "-";
    const date = testDetail.meta?.modifiedDate && moment(parseInt(testDetail.meta?.modifiedDate as any, 10)).format(
        "MMM DD, YYYY (h:mm A)"
     );

    return (
        <>
            <Container>
                <Row>
                    <Key>Test ID</Key>
                    <Value>{testDetail?._id}</Value>
                </Row>
                <Row>
                    <Key>Test Administrator</Key>
                    <Value>{deviceUserName}</Value>
                </Row>
                <Row>
                    <Key>Test Date/Time</Key>
                    <Value>{date}</Value>
                </Row>
                <Row>
                    <Key>Case Number</Key>
                    <Value>{testDetail?.caseNumber}</Value>
                </Row>

            </Container>
        </>
    );
}
