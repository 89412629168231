import { ApolloClient, InMemoryCache, HttpLink, from, ApolloLink, Observable } from "@apollo/client";
import errorLink from './links/errorLink';
import requestLink from './links/requestLink';
import uploadLink from './links/uploadLink';

const cache = new InMemoryCache();

const link = ApolloLink.from([
  requestLink,
  errorLink,
  uploadLink,
]);

export default new ApolloClient({ link, cache });
