import moment from "moment";
const LOCALSTORAGE_AUTH_USER = "AuthUser";
const LOCALSTORAGE_TOKEN_KEY = "AuthToken";
const LOCALSTORAGE_REFRESH_TOKEN_KEY = "AuthRefreshToken";
const LOCALSTORAGE_USERNAME = "UserName";
const GET_ACTIVE_TAB = "ActiveTab"
const USER_DATA = "userData"

const storeAuthToken = (token) => {
    localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
};
const storeAuthRefreshToken = (token) => {
    localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN_KEY, token);
};
const storeUserSub = (token) => {
    localStorage.setItem(LOCALSTORAGE_USERNAME, token);
};

const removeAuthToken = () => {
    localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
};
const getAuthRefreshToken = () => {
    const refreshToken = localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN_KEY);
    if (refreshToken) {
        return refreshToken
    }
    return;
};

const getUserSub = () => {
    return localStorage.getItem(LOCALSTORAGE_USERNAME);
};
const getUserData = () => {
    const user = localStorage.getItem(USER_DATA);
    if (user) {
        return JSON.parse(user)
    }
    return false
};

const getActiveTab = () => {
    const active = localStorage.getItem(GET_ACTIVE_TAB);
    if (active) {
        return active;
    }
    return;
}

const storeActiveTab = (activeTab) => {
    localStorage.setItem(GET_ACTIVE_TAB, activeTab);
}

const clearLocalStorage = () => {
    localStorage.clear();
};

export const localStorageService = {
    clearLocalStorage,
    storeAuthToken,
    removeAuthToken,
    storeUserSub,
    getUserSub,
    storeAuthRefreshToken,
    getAuthRefreshToken,
    getActiveTab,
    storeActiveTab,
    getUserData
};
