import gql from 'graphql-tag';

const mutation = gql`
mutation createDevice($params: DeviceInput!){
    createDevice(params:$params){
        _id
        organizationId
        serialNumber
        pairingCode
        active
        deviceModelId
        softwareVersion
        assetId
        assignedUserId
    }
}
`;

export default mutation;
