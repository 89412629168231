import gql from 'graphql-tag';

const query = gql`
query getUserByFirebaseId{
    user: getUserByFirebaseId{
        _id      
        organizationId,
        firstName,
        lastName,
        firebaseId,
        title,
        email,
        phoneNumber,
        role,
        active,
        passwordResetCode,
            organization{
                _id
                name
                ownerFirstName
                ownerLastName
                ownerTitle
                ownerPhone
                ownerPhoneExten
                ownerEmail
                logoUrl,
                address{
                    addressLine1
                    addressLine2
                    city
                    state
                    country
                    zipCode
                },
                active
                billing{
                    planId
                    status
                    stripeCustomerId
                    paidThrough,
                    nextPaymentDate
                    paymentMethodDesc
                    cardDetails{
                        brand
                        expMonth
                        expYear
                        lastDigits
                    },
                }
                payments{
                    stripeChargeId
                    paidDateTime
                    amount
                    taskId
                }
            }
        meta{
            createdBy,
            createdDate,
            modifiedBy,
            modifiedDate
        }   
    }
}
`;

export default query;