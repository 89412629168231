import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../Header";
import LoaderContainer from "../elements/LoaderContainer";
import Loader from "../elements/Loader";
import { GaizeState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import GET_USER from "../../graphql/queries/getUserByFirebaseId.query";
import { useNavigate } from 'react-router-dom';
import { setLogoDropdown, setOrganization } from "../../redux/user/user.actions";
import routePaths from "../../config/routepaths.config";
import { localStorageService } from "../../services/localstorage.service";
import toast from "react-hot-toast";

const AdminLayoutContainer = styled.div`
`;

const MainContent = styled.div`
`;

const ContentWrapper = styled.div`
`;

const DashboardLayout = ({ children }: { children: JSX.Element }) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const userState: any = useSelector((state: GaizeState) => state.user);
   const { organization, logoDropdown } = userState;
   const [humburgerMenu, setHumburgerMenu] = React.useState<boolean>(false);

   /* Get Organization */

   const [getUserQuery, { loading }] = useLazyQuery(GET_USER, {
      onCompleted: (data) => {
         dispatch(setOrganization(data.user));
         if (!data?.user?.active) {
            localStorageService.clearLocalStorage();
            dispatch(setOrganization(""));
            navigate(routePaths.LOGIN);
         }
      },
      onError: async (error) => {
         console.error(error)
      },
   });

   const onMainMenu = () => {
      dispatch(setLogoDropdown(false));
      setHumburgerMenu(false);
   }


   useEffect(() => {
      // Getting current user and org data if it not exists in redux
      // eslint-disable-next-line no-prototype-builtins
      if (!organization?.hasOwnProperty("user")) {
         getUserQuery();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


   return (
      <>
         <AdminLayoutContainer>
            <Header organization={organization} logoDropdown={logoDropdown} humburgerMenu={humburgerMenu} setHumburgerMenu={setHumburgerMenu} />
            <MainContent onClick={onMainMenu} >
               {loading ?
                  <LoaderContainer>
                     <Loader />
                  </LoaderContainer>
                  :
                  <ContentWrapper >{children}</ContentWrapper>
               }
            </MainContent>
         </AdminLayoutContainer>
      </>
   );
};

export default DashboardLayout;
