import { Navigate, useLocation } from "react-router-dom";
import DashboardLayout from "../components/layouts/DashboardLayout";
import routePaths from "../config/routepaths.config";
import { useNavigate } from "react-router-dom";
const errorImg = require("../assets/images/error_msg.gif");
import * as Auth from "../util/Auth";
import { useSelector } from "react-redux";
import { GaizeState } from "../redux/store";
import { useCallback, useEffect } from "react";
import { ErrorImage } from "../pages/SuperAdmin";

const DashboardRoute = ({
   children,
   restrictedRoles = [],
}: {
   children: JSX.Element;
   restrictedRoles?: string[];
}) => {
   let location = useLocation();
   /**
    * Hooks section
    */
   const navigate = useNavigate();
   /**
    * Get user details section
    */
   const userState = useSelector((state: GaizeState) => state.user);
   const { organization } = userState;
   const currentRole = organization.role;
   const checkAuthentication = useCallback(() => {
      const isAuthenticated = Auth.getToken();
      if (!isAuthenticated) {
         return Auth.logout();
      } else if (restrictedRoles?.length) {
         if (restrictedRoles.includes(currentRole)) {
            return <ErrorImage src={errorImg} />;
         }
      }
   }, [restrictedRoles, currentRole]);
   useEffect(() => {
      checkAuthentication();
   }, [checkAuthentication]);
   return (
      <>
         <DashboardLayout>{children}</DashboardLayout>
      </>
   );
};

export default DashboardRoute;
