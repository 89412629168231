import gql from "graphql-tag";

const query = gql`
   query getDeviceBySerialNumber($serialNumber: String!) {
      getDeviceBySerialNumber(serialNumber: $serialNumber) {
         _id
         serialNumber
         active
         organizationId,
         organization{
            _id,
            name
         }
      }
   }
`;

export default query;
