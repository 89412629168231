export enum Colors {
   blue= "#2d4de1",
   lightblue= "#2491ebd1",
   lightBlack= "#333",
   black= "#000000",
   black1= "#302E2E",
   white= "#FFFFFF",
   blackBlue= "#3E4C6F",
   grey= "#828282",
   whiteGrey= "#F2F2F2",
   seaGreen= "#27BBAD",
   lightGrey= "#BDBDBD",
   darkGrey= "#4F4F4F",
   red= "#E63946",
   lightRed= "#fff5f8",
   background= "#FAFAFA",
   green= "#50cd89",
   lightGreen= "#e8fff3",
   orange= "#FF6802",
   cream= "#FFF9F5",
   lightCream= "#FFFDFB",
   smoke= "#FBFBFF",
   darkBlue= "#083D77",
   grey1= "#333333",
   grey2= "#4F4F4F",
   grey3= "#828282",
   grey4= "#BDBDBD",
   grey5= "#E0E0E0",
   grey6= "#F2F2F2",
   yellow= "#FFBA49",
   purple= "#1E1449",
   lightPurple= " #9999cc",
   borderGrey="#eeeeee",
   gray7= "#5f5f5f",
   darkGray= "#7A8EA5"

};
