import React, { useCallback, useEffect } from "react";
import Joi from "joi";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";
import Button from "../elements/button/Button";
import Input from "../../components/elements/Input";
import StyledSelect from "../elements/DropDown";
import CREATE_DEVICE from "../../graphql/mutations/createDevice.mutation";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import GET_SERIAL_NUMBER from "../../graphql/queries/getDeviceBySerialNumber.query";
import GET_ALL_DEVICES_MODEL from "../../graphql/queries/getDeviceModels.query";
import ErrorText from "../../components/elements/ErrorText";
import * as ErrorUtil from "../../util/ErrorUtil";
import * as Schema from "../../util/Schema";
import makeEventHandler from "../../util/MakeEventHandler";
import GET_DEVICES from "../../graphql/queries/getDevices.query";
import { useSelector } from "react-redux";
import { GaizeState } from "../../redux/store";
import { RequiredInput } from "../../pages/AddAccount";
import { successMessages } from "../../config/messages.config";
import BaseModal, { ModalList } from "./BaseModal";

const schema = Joi.object({
   serialNumber: Schema.devices.serialNumber().error(([error]) => {
      const message = "Serial Number is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),

   model: Schema.devices.model().error(([error]) => {
      const message = "Model is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),
});

const Container = styled.div`
   padding: 20px 15px;
`;

const Form = styled.div``;

const Spacer = styled.div`
   margin: 10px;
`;

const ButtonContainer = styled.div`
   margin-top: 20px;
   display: flex;
   justify-content: center;
   & Button:first-child {
      margin-right: 10px;
   }
`;

enum ErrorKeyEnum {
   SerialNumber = "serialNumber",
   Model = "model",
}

export default function AddNewDevice({ close }) {
   const user = useSelector((state: GaizeState) => state.user as any);
   const userData = user?.organization;
   const [serialNumber, setSerialNumber] = React.useState("");
   const [model, setModel] = React.useState("");
   const [isSuccess, setIsSuccess] = React.useState(false);
   const [loading, setLoading] = React.useState(false);
   const [openNotificationModal, setOpenNotificationModal] =
      React.useState(false);
   const [error, setError] = React.useState("");
   const [fieldErrors, setFieldErrorsInternal] = React.useState({
      [ErrorKeyEnum.SerialNumber]: null,
      [ErrorKeyEnum.Model]: null,
   });

   const eventHandler = makeEventHandler(() => setError(""));

   const setFieldErrors = (field: string, message: string | null) => {
      const newFieldErrors: any = {
         [field]: message,
      };
      setFieldErrorsInternal(newFieldErrors);
   };

   const onChangeSerialNumber = eventHandler((value: string) => {
      setFieldErrors(ErrorKeyEnum.SerialNumber, null);
      setSerialNumber(value);
   });

   type selectedStateType = {
      value: string;
      label: string;
   };
   const [selectedModel, setSelectedModel] =
      React.useState<selectedStateType | null>(() => {
         return null;
      });

   useEffect(() => {
      setModel(selectedModel?.value as any);
   }, [selectedModel]);

   useEffect(() => {
      if (isSuccess) {
         createDeviceMutation({
            variables: {
               params: {
                  organizationId: userData?.organizationId,
                  serialNumber: serialNumber,
                  deviceModelId: model,
                  active: true,
               },
            },
         });
      }
   }, [isSuccess]); // eslint-disable-line

   // Get Model Data
   const { data: devicesModel } = useQuery(GET_ALL_DEVICES_MODEL);
   const modelOptions = devicesModel?.getDeviceModels.map((data) => {
      return {
         label: data.name,
         value: data._id,
      };
   });

   // Create Device
   const [createDeviceMutation] = useMutation(CREATE_DEVICE, {
      refetchQueries: [
         {
            query: GET_DEVICES,
         },
      ],
      onCompleted: async (data) => {
         setIsSuccess(false);
         toast.success(successMessages.DEVICE_ADD_SUCCESS);
         localStorage.setItem("deviceAdd", "true");
         setError("");
         close();
      },
      onError: async (error) => {
         const errorMsg = ErrorUtil.getErrorMessage(error);
         setError(errorMsg);
         if (error) {
            toast.error(errorMsg);
         }
         setLoading(false);
      },
   });
   const [getSerialNumber, { data }] = useLazyQuery<any>(GET_SERIAL_NUMBER, {
      variables: {
         serialNumber: serialNumber,
      },
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
         if (
            data?.getDeviceBySerialNumber?.organizationId ==
            userData?.organizationId
         ) {
            toast.error("Already exists");
            setLoading(false);
         } else {
            setOpenNotificationModal(true);
         }
      },
      onError: () => {
         createDeviceMutation({
            variables: {
               params: {
                  organizationId: userData?.organizationId,
                  serialNumber: serialNumber,
                  deviceModelId: model,
                  active: true,
               },
            },
         });
      },
   });
   const createDevice = (event?: React.FormEvent<HTMLFormElement>) => {
      if (event) {
         event.preventDefault();
      }
      const params = schema.validate({
         serialNumber,
         model,
      });

      const { error: schemaError } = params;
      if (schemaError) {
         const { field, message } = JSON.parse(schemaError.message);
         setFieldErrors(field, message);
         return;
      }
      setError("");
      toast.remove();
      getSerialNumber({ variables: { serialNumber: serialNumber } });
      setLoading(true);
   };

   return (
      <Container>
         <Form>
            <RequiredInput>
               <Input
                  type="text"
                  header="Serial Number"
                  placeholder="Serial Number"
                  value={serialNumber}
                  onChange={onChangeSerialNumber}
                  error={fieldErrors[ErrorKeyEnum.SerialNumber]}
                  top="10px"
                  width="100%"
               />
            </RequiredInput>
            <Spacer />
            <RequiredInput>
               <StyledSelect
                  header="Model"
                  placeholder="Model"
                  options={modelOptions}
                  value={selectedModel}
                  onChange={({ value, label }: any) => {
                     setSelectedModel({ label: label, value });
                  }}
                  width="100%"
               />
            </RequiredInput>
            {!selectedModel && (
               <ErrorText margin="0 0 0 0">
                  {fieldErrors[ErrorKeyEnum.Model]}
               </ErrorText>
            )}
            <Spacer />
         </Form>
         <ButtonContainer>
            <Button
               margin="20px 0px 0px 0px"
               text="Save"
               loading={loading}
               disabled={loading} //To Do we have to change the color when disabled
               onClick={createDevice}
               width="100%"
            />
            <Button
               margin="20px 0px 0px 0px"
               text="Cancel"
               width="100%"
               onClick={() => close()}
            />
         </ButtonContainer>
         <BaseModal
            open={openNotificationModal}
            modalType={ModalList.DeviceConfirmation}
            onClose={setOpenNotificationModal}
            setIsSuccess={setIsSuccess}
            userData={userData}
            setLoading={setLoading}
            organization={data?.getDeviceBySerialNumber?.organization}
         />
      </Container>
   );
}
