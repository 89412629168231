import { localStorageService } from "../../services/localstorage.service";
import * as Auth from "../../util/Auth";
import { Observable } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
      let UNAUTHENTICATED
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          if(extensions.code === 401) {
              UNAUTHENTICATED = true;
          }
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extension: ${extensions.code}`);
      });
      if(UNAUTHENTICATED) {
          return new Observable((observer) => {
              const headers = operation.getContext().headers;
              resendRefreshToken().then((accessToken) => {
                  operation.setContext({
                      headers: {
                          ...headers,
                          authorization: `Bearer ${accessToken}`
                      }
                  });
                  const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                  };
                  return forward(operation).subscribe(subscriber);
              }).catch(error => {
                  console.error(error)
              })
          })
      }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});


export async function resendRefreshToken() {
  const refreshToken = localStorageService.getAuthRefreshToken();
  const axios = require('axios');
  const data = JSON.stringify({
    "grant_type": "refresh_token",
    "refresh_token": refreshToken
  });

  const config = {
    method: 'post',
    url: `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  const response = await axios(config);
  const newAccessToken = response.data.access_token;

  Auth.setToken(newAccessToken);
  return newAccessToken;
}

export default errorLink;
