import gql from 'graphql-tag';

const query = gql`
query getOrganizations {
    getOrganizations {
      _id
      name
      ownerFirstName
      ownerLastName
      ownerTitle
      ownerPhone
      ownerPhoneExten
      ownerEmail
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
        country
      }
      active
      billing {
        planId
        status
        stripeCustomerId
        paidThrough
        nextPaymentDate
        paymentMethodDesc
      }
      payments {
        stripeChargeId
        paidDateTime
        amount
        taskId
      }
       meta {
      createdBy,
      createdDate,
      modifiedBy,
      modifiedDate
              } 
    }
  }
`;
export default query;