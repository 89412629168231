import Input from "../elements/Input";
import Joi from "joi";
import * as Schema from "../../util/Schema";
import {
   Form,
   FormContent,
   FormContentInner,
   Spacer,
} from "../layouts/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import { BackContainer, BackText, ErrorKeyEnum } from "../../pages/SetPassword";
import routePaths from "../../config/routepaths.config";
import Icon, { Icons } from "../elements/Icon";
import { Colors } from "../../util/Colors";
import React, { useState } from "react";
import ErrorText from "../elements/ErrorText";
import Button from "../elements/button/Button";
import { Container } from "./UpdateCardInfoModal";
import { setOrganization } from "../../redux/user/user.actions";
import { useMutation } from "@apollo/client";
import { localStorageService } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import * as ErrorUtil from "../../util/ErrorUtil";

import CHANGE_PASSWORD from "../../graphql/mutations/changePassword.mutation";
import { signInWithEmailAndPassword } from "firebase/auth";
import toast, { Toaster } from "react-hot-toast";
import { errorMessages, successMessages } from "../../config/messages.config";
import { auth } from "../../firebase";
import { GaizeState } from "../../redux/store";

const ForgotPassword = ({ close }: any) => {
   const user = useSelector((state: GaizeState) => state.user as any);
   const email = user?.organization?.email;
   const [newPassword, setNewPassword] = useState({ value: "", error: "" });
   const [confirmPassword, setConfirmPassword] = useState({
      value: "",
      error: "",
   });
   // eslint-disable-next-line
   const passwordRegex =
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&’()*+,-.\/:;<=>?@[\]^_`{|}~]).{8,}$/;

   const regexError =
      "Password must be at least 8 characters, and contain both upper and lower case characters, at least one number, and at least 1 special character.";

   const passwordMismatchError = "Password must match";

   const [oldPassword, setOldPassword] = useState({ value: "", error: "" });

   const [error, setError] = useState("");

   const dispatch = useDispatch();

   const navigate = useNavigate();

   const validatePasswords = (newPasswordValue, confirmPasswordValue) => {
      const hasRegExError = !passwordRegex.test(newPasswordValue);
      if (hasRegExError) {
         setNewPassword((old) => ({
            ...old,
            value: newPasswordValue,
            error: regexError,
         }));
         return false;
      }
      setNewPassword((old) => ({
         ...old,
         value: newPasswordValue,
         error:
            newPasswordValue !== confirmPasswordValue
               ? passwordMismatchError
               : "",
      }));
      setConfirmPassword((old) => ({
         ...old,
         value: confirmPasswordValue,
         error:
            newPasswordValue !== confirmPasswordValue
               ? passwordMismatchError
               : "",
      }));
   };

   const [changePasswordMutation, { loading }] = useMutation(CHANGE_PASSWORD, {
      onCompleted: async (data) => {
         toast.success(successMessages.PASSWORD_CHANGED_SUCCESS);
         //Old functionality
         {
            // localStorageService.clearLocalStorage();
            // dispatch(setOrganization(""));
            // navigate(routePaths.LOGIN);
         }
         close();
      },
      onError: async (error: any) => {
         const errorMsg = ErrorUtil.getErrorMessage(error);
         setError(errorMsg);
         toast.error(errorMessages.SOMETHING_WENT_WRONG);
      },
   });

   async function addNewPassword() {
      signInWithEmailAndPassword(auth, email, oldPassword?.value)
         .then(async (response) => {
            changePasswordMutation({
               variables: {
                  params: {
                     password: newPassword?.value,
                     oldPassword: oldPassword?.value,
                  },
               },
            });
         })
         .catch((error) => {
            toast.remove();
            toast.error(errorMessages.OLD_WRONG_PASSWORD);
         });
   }

   return (
      <>
         
         <Container>
            <FormContent width="100%">
               <FormContentInner>
                  <Form
                     onSubmit={(e) => {
                        e.preventDefault();
                        if (!newPassword.error && !confirmPassword.error) {
                           addNewPassword();
                        }
                     }}
                  >
                     <Input
                        type={"password"}
                        header="Old Password"
                        placeholder="Please enter old password"
                        required
                        value={oldPassword.value}
                        width="100%"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           setOldPassword({ value: e.target.value, error: "" });
                        }}
                        error={oldPassword.error}
                     />

                     <Input
                        type={"password"}
                        header="New Password"
                        placeholder="Please enter new password"
                        required
                        value={newPassword.value}
                        width="100%"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           validatePasswords(
                              e.target.value,
                              confirmPassword.value || ""
                           );
                        }}
                        error={newPassword.error}
                     />

                     <Input
                        type="password"
                        header="Confirm Password"
                        placeholder="Please confirm your password"
                        required
                        value={confirmPassword.value}
                        width="100%"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           validatePasswords(
                              newPassword.value || "",
                              e.target.value
                           );
                        }}
                        error={confirmPassword.error}
                     />
                     <Spacer />
                     <span style={{ fontSize: "13px", margin: "5px 0" }}>
                        Password must be at least 8 characters, and contain both
                        upper and lower case characters, at least one number,
                        and at least 1 special character.
                     </span>
                     <Button
                        margin="0px 0px 30px 0px"
                        text="Confirm"
                        type="submit"
                        width="100%"
                        loading={loading}
                        disabled={loading}
                     />
                  </Form>
               </FormContentInner>
            </FormContent>
         </Container>
      </>
   );
};
export default ForgotPassword;
