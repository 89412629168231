import client from "../graphql/client";

const GAIZE_AUTH_TOKEN = "GAIZE_AUTH_TOKEN";

export function setToken(token: string) {
  return localStorage.setItem(GAIZE_AUTH_TOKEN, token);
}

export function getToken(): string | null {
  return localStorage.getItem(GAIZE_AUTH_TOKEN);
}

export function removeAuthToken() {
  return localStorage.clear();
}

export async function logout() {
  localStorage.clear();
  client.resetStore();
  window.location.href = '/';
}
export async function logoutToPassword() {
  localStorage.removeItem(GAIZE_AUTH_TOKEN);
  window.location.href = '/signin';
}
