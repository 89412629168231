import { useCallback, useEffect } from "react";
import AuthLayout from "../components/layouts/AuthLayout";
import { useSelector } from "react-redux";
import { GaizeState } from "../redux/store";
import { Navigate, useNavigate } from "react-router-dom";
import { logout } from "../util/Auth";
import { ErrorImage } from "../pages/SuperAdmin";
const errorImg = require("../assets/images/error_msg.gif");

const AuthRoute = ({
   children,
   restrictedRoles = [],
}: {
   children: JSX.Element;
   restrictedRoles?: string[];
}) => {
   /**
    * Hooks section
    */
   const navigate = useNavigate();
   /**
    * Get user details section
    */
   const userState = useSelector((state: GaizeState) => state.user);
   const { organization } = userState;
   const currentRole = organization.role;
   /**
    * UseEffect section
    */
   const checkUserAllowed = useCallback(() => {
      if (restrictedRoles?.length) {
         if (restrictedRoles.includes(currentRole)) {
            return <ErrorImage src={errorImg} />;
         }
      }
   }, [currentRole, restrictedRoles]);

   useEffect(() => {
      checkUserAllowed();
   }, [checkUserAllowed]);

   return (
      <>
         <AuthLayout>{children}</AuthLayout>
      </>
   );
};

export default AuthRoute;
