import gql from 'graphql-tag';

const query = gql`
query getDeviceModels{
    getDeviceModels{
        _id,
        name
        price
        active
    }

}
`;

export default query;