import gql from 'graphql-tag';

const mutation = gql`
mutation updateOrganization($params: OrganizationInput!){
    updateOrganization(params:$params){
        _id,
    }
}
`;

export default mutation;