import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "../src/App";
import { ApolloProvider } from "@apollo/react-hooks";
import { Provider as ReduxProvider } from 'react-redux';
import client from './graphql/client';
import store from './redux/store';
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "./env";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
import "./index.css";


ReactDOM.render(
   <ApolloProvider client={client as any}>
     <ReduxProvider store={store}>
       <BrowserRouter>
       <Elements stripe={stripePromise} >
           <App />
         </Elements>
       </BrowserRouter>
     </ReduxProvider>
   </ApolloProvider>,
   document.getElementById("root")
 );