// Import the functions you need from the SDKs you need
import * as firebase from 'firebase/app';
import {
  getAuth,
  // connectAuthEmulator 
} from "firebase/auth";
import "firebase/firestore";
import {
  getStorage,
  // connectStorageEmulator
} from "firebase/storage";
// import { connectDatabaseEmulator,getDatabase} from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});


// Initialize Firebase
export const auth = getAuth(app);
// For Local emulator usage only
// connectAuthEmulator(auth, "http://localhost:9099");

export const storage = getStorage(app);
// For Local emulator usage only
// connectStorageEmulator(storage, "localhost", 9199);

// export const database = getDatabase(app);
// connectDatabaseEmulator(database, "localhost", 9000);

export default app;