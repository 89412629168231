import styled from "styled-components";
import { Colors } from "../../util/Colors";
const Logo = require("../../assets/images/logo.png");
import { Link } from "react-router-dom";
import { CONTACT_US_URL } from "../../env";

const AuthContainer = styled.div`
   display: flex;
   height: 100vh;
   @media screen and (max-width: 768px) {
      flex-direction: column;
   }
`;

export const LeftWrapper = styled.div`
   width: 30%;
   height: 100%;
   background-color: #2d4de1;
   padding: 95px 0 95px;
   @media screen and (max-width: 768px) {
      width: 100%;
      padding: 25px 0 25px;
      height: auto;
   }
`;
export const InnerWrapper = styled.div`
   width: 100%;
   position: relative;
   height: 100%;
`;

export const ContentWrapper = styled.div`
   display: flex;
   height: 100vh;
`;

export const LogoWrapper = styled.div`
   width: 240px;
   height: 240px;
   background-color: #fff;
   border-radius: 5px;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 auto 30px;
   @media screen and (max-width:991px){
      width:120px;
      height:120px;
   }
   }
`;
export const LogoImage = styled.img<any>`
   width: ${(props) => (props.src === Logo ? "70%" : "90%")};
   height: ${(props) => (props.src === Logo ? "auto" : "90%")};
`;

export const FooterText = styled.div`
   text-align: center;
   color: #fff;
   font-size: 18px;
   letter-spacing: 0.8px;
   position: absolute;
   left: 0;
   bottom: 0;
   width: 100%;
   a {
      span {
         color: ${Colors.white};
         font-size: 18px;
      }
   }
`;

export const LinkText = styled.span`
   margin-top: 10px;
   font-size: 1rem;
   color: ${Colors.blue};
   align-self: baseline;
   cursor: pointer
   margin-left: 10px;
   &:hover {
      color: ${Colors.black};
   }
`;

export const FormContent = styled.div<any>`
   width: ${(props: any) => (props.width ? props.width : "70%")};
   display: flex;
   justify-content: center;
   padding: 20px 30px;
   overflow: auto;
   height: 100%;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: stretch;
   @media screen and (max-width: 768px) {
      width: 100%;
      padding: 20px 15px;
      overflow: inherit;
      height: auto;
   }
`;

export const FormContentInner = styled.div`
   width: 100%;
   align-self: center;
   max-width: 440px;
`;

export const Text = styled.div`
   text-align: center;
   color: #fff;
   font-weight: 700;
   font-size: 30px;
   letter-spacing: 0.8px;
   margin: 0 0 5px 0;
`;

export const Email = styled.div`
   text-align: center;
   color: #fff;
   font-size: 20px;
   letter-spacing: 0.8px;
`;

export const Form = styled.form`
   display: flex;
   flex-direction: column;
   width: 100%;
`;
export const Spacer = styled.div`
   height: 20px;
`;
export const LogoImageContainer = styled.div`
   height: 100%;
   display: flex;
   justify-content: center;
   margin-bottom: 25px;
`;

export const Heading = styled.div`
   font-style: normal;
   font-size: 40px;
   line-height: 32px;
   letter-spacing: -0.165px;
   color: #302e2e;
   margin: 0 0 25px 0;
   font-family: "proxima_novaBold";
   @media screen and (max-width: 991px) {
      font-size: 28px;
      margin: 0 0 15px 0;
   }
`;

export const FormHeading = styled.div`
   font-size: 17px;
   font-weight: 600;
   color: ${Colors.gray7};
   margin: 20px 0px 8px 0px;
`;

export const Row = styled.div<any>`
   position: relative;
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   justify-content: center;
   margin-bottom: ${(props) => (props.margin ? "20px" : "")};
   width: 100%;
   flex-wrap: wrap;
`;

export const LogoImages = styled.img`
   width: 115px;
`;

const AuthLayout = ({ children }: { children: JSX.Element }) => {
   let localData;
   if (localStorage?.userData) {
      localData = JSON.parse(localStorage?.userData);
   }

   return (
      <>
         <AuthContainer>
            <LeftWrapper>
               <InnerWrapper>
                  <LogoWrapper>
                     <LogoImage
                        src={
                           localData?.organization?.logoUrl
                              ? localData?.organization?.logoUrl
                              : Logo
                        }
                     />
                  </LogoWrapper>
                  <FooterText>
                     Need a Gaize account?
                     <Link
                        to="#"
                        onClick={(e) => {
                           window.location.href = `mailto:${CONTACT_US_URL}`;
                           e.preventDefault();
                        }}
                     >
                        <LinkText> Contact us.</LinkText>
                     </Link>{" "}
                  </FooterText>
               </InnerWrapper>
            </LeftWrapper>
            {children}{" "}
         </AuthContainer>
      </>
   );
};

export default AuthLayout;
