import "./App.css";
import BaseRoutes from "./routes/Routes";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useMemo, useState } from "react";
import { AppContext } from "./components/context/AppContext";
import { UserContext } from "./components/context/UserContext";
import { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";

const stripePromise = loadStripe(
   process.env.REACT_APP_STRIPE_PUBLISH_KEY || ""
);
import GET_USER from "./graphql/queries/getUserByFirebaseId.query";
import { setOrganization } from "./redux/user/user.actions";
import { localStorageService } from "./services";
import routePaths, {
   hasPermisison,
   navigateToRoleHome,
} from "./config/routepaths.config";

function App() {
   const [app, setApp] = useState({ showSideNav: true });
   const [user, setUser] = useState({});
   const navigate = useNavigate();
   const location = useLocation();
   const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);
   const appValue = useMemo(() => ({ app, setApp }), [app, setApp]);
   const userData = localStorageService.getUserData();
   const permitted = hasPermisison(userData?.role, location);

   useEffect(() => {
      if (!permitted) {
         navigateToRoleHome(userData?.role, navigate);
      } else {
         if (userData) {
            if (location?.pathname == routePaths.LOGIN || location?.pathname == routePaths.FORGOT_PASSWORD) {
               navigateToRoleHome(userData?.role, navigate);
            }
         }
      }
   }, [permitted, userData, navigate, location]);

   return (
      <>
         <Toaster
            toastOptions={{
               // Define default options
               className: "",
               duration: 2000,
               style: {
                  background: "#363636",
                  color: "#fff",
                  fontSize: "14px",
               },
               success: {
                  style: {
                     background: "green",
                  },
               },
               error: {
                  style: {
                     background: "red",
                  },
               },
            }}
            position="bottom-right"
         />
         <Elements stripe={stripePromise}>
            <AppContext.Provider value={appValue}>
               <UserContext.Provider value={userValue as any}>
                  <BaseRoutes />
               </UserContext.Provider>
            </AppContext.Provider>
         </Elements>
      </>
   );
}

export default App;
