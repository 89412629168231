import React, { useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../util/Colors";
import { useMutation, useQuery } from '@apollo/client';
const errorImg = require("../assets/images/error_msg.gif");
import { localStorageService } from "../services";
import { useNavigate } from "react-router-dom";
import routePaths from "../config/routepaths.config";
import GET_ORGANIZATIONS from "../graphql/queries/getOrganizations.query";
import LOGIN_TO_ORGANIZATION from "../graphql/mutations/loginToOrganization.mutation";
import moment from "moment";
import { setOrganization } from "../redux/user/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { GaizeState } from "../redux/store";
import Loader, { LoaderSizes } from "../components/elements/Loader";
import LoaderContainer from "../components/elements/LoaderContainer";
import { Roles } from "../models/enums/RolesEnum";
import { CardTable, CardTableBody, CardTableThead, CardTd, CardTh, CardTr, NoContentText, TableResponsive } from "../pages/DeviceView";
import toast, { Toaster } from "react-hot-toast";
import { successMessages } from "../config/messages.config";


const MainWrapper = styled.div`
  padding:40px 0;
  margin:64px 0 0 0;
`;
export const Container = styled.div`
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   margin-right: auto;
   margin-left: auto;
   max-width: 1140px;
   }
`;
const SubHeader = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin:0 0 30px 0;
`;
const Heading = styled.div`
   font-weight: 700;
   font-size: 28px;
   line-height: 32px;
   letter-spacing: -0.165px;
   color: #302E2E; 
`;
export const Card = styled.div`
  margin:0 0 50px 0;
  &:last-child{
    margin:0;
  }
`;
export const CardHeader = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin:0px 0 15px 0;
`;
export const LeftWrapper = styled.div`
display: flex;
`;
export const CardTitle = styled.div`
font-weight: 700;
font-size: 21px;
line-height: 24px;
letter-spacing: -0.165px;
color: #302E2E;
margin:0 30px 0 0;
`;
export const CardSubTitle = styled.div`
font-weight: 600;
font-size: 15px;
line-height: 24px;
letter-spacing: -0.165px;
color: #7A8EA5;
cursor:pointer;
`;
export const CardBody = styled.div`

`;

export const MapWrapper = styled.div`
    width:100%;
    height: calc(100vh - 253px);
   }
`;
export const MapImage = styled.img`
width:100%;
`;
export const ErrorImage = styled.img`
   max-width:100%;
   width:100%;
   height:100vh;
`;

const LoginButton = styled.div`
 color: ${Colors.blue};
 font-weight: 600;
 margin-right: 15px;
 display: inline-block;
 &:hover {
   cursor: pointer;
 }
`;

const SuperAdmin = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const user = useSelector((state: GaizeState) => state.user as any);
   const userData = user?.organization;
   const userRole = userData?.role;
   const pathname = window.location.pathname;
   const addOrg = localStorage.getItem("addOrg");

   /*State*/
   const [isDataLoading, setIsDataLoading] = React.useState(true);
   const [organizationsDetail, setOrganizationsDetail] = React.useState([]);

   useEffect(() => {
      if (addOrg) {
         toast.success(successMessages.ORGANIZATION_ADD_SUCCESS);
      }
      localStorage.setItem("addOrg", "");
   }, [addOrg]); //eslint-disable-line

   //  Actions
   const onCreateNewAccount = () => {
      navigate(routePaths.ADD_ACCOUNT);
   }

   // Get User Data
   const { data } = useQuery(GET_ORGANIZATIONS, {
      variables: {},
      fetchPolicy: "network-only",
      onCompleted: (data) => {
         setIsDataLoading(false);
         setOrganizationsDetail(data?.getOrganizations);
      }
   });

   /* Sign in function */
   const [loginToOrganization] = useMutation(LOGIN_TO_ORGANIZATION, {
      onCompleted: ({ loginToOrganization }) => {
         if (loginToOrganization) {
            dispatch(setOrganization(loginToOrganization));
            localStorageService.storeActiveTab("Home");
            navigate(routePaths.HOME);
         }
      },
   });

   return (
      <>
     
        {pathname === "/superAdmin" && userRole === Roles.SuperAdmin ? <MainWrapper>
            <Container>
               <Card>
                  <CardHeader>
                     <LeftWrapper>
                        <CardTitle>Clients</CardTitle>
                        <CardSubTitle onClick={onCreateNewAccount}>Create New Account</CardSubTitle>
                     </LeftWrapper>
                  </CardHeader>
                  <CardBody>
                     <TableResponsive>
                        <CardTable>
                           <CardTableThead>
                              <CardTr>
                                 <CardTh>Organization ID</CardTh>
                                 <CardTh>Name</CardTh>
                                 <CardTh>Email</CardTh>
                                 <CardTh>Phone Number</CardTh>
                                 <CardTh>Created</CardTh>
                                 <CardTh>Actions</CardTh>
                              </CardTr>
                           </CardTableThead>
                           <CardTableBody>
                              {organizationsDetail && organizationsDetail.map((data: any, i) => {
                                 const date = data.meta?.createdDate && moment(parseInt(data.meta?.createdDate as any, 10)).format(
                                    "MMM DD, YYYY (h:mm A)"
                                 );
                                 const onLoginClick = () => {
                                    loginToOrganization({
                                       variables: {
                                          organizationId: data._id as string,
                                       },
                                    });
                                 };
                                 return (
                                    <CardTr key={i}>
                                       <CardTd>{data._id}</CardTd>
                                       <CardTd>{data.name}</CardTd>
                                       <CardTd>{data.ownerEmail}</CardTd>
                                       <CardTd>{data.ownerPhone}</CardTd>
                                       <CardTd>{date}</CardTd>
                                       <CardTd>
                                          <LoginButton onClick={() => onLoginClick()}> Login </LoginButton>
                                       </CardTd>
                                    </CardTr>);
                              })}
                           </CardTableBody>
                        </CardTable>
                        {isDataLoading ? (
                                 <LoaderContainer>
                                    <Loader color={Colors.blue} size={LoaderSizes.Medium} />
                                 </LoaderContainer>
                              ) : organizationsDetail?.length === 0 && <NoContentText>No Records Found </NoContentText>}
                           </TableResponsive>
                  </CardBody>
               </Card>
            </Container>
         </MainWrapper> : <ErrorImage src={errorImg} /> }
      </>
   );
};
export default SuperAdmin;

