import gql from 'graphql-tag';

const mutation = gql`
mutation updateDevice($params: DeviceInput!){
    updateDevice(params:$params){
        _id
        organizationId
        serialNumber
        pairingCode
        active
        deviceModelId
        softwareVersion
        assetId
        assignedUserId            }
}
`;

export default mutation;
