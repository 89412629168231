import gql from 'graphql-tag';

const mutation = gql`
mutation createOrganization($params: OrganizationInput!) {
    createOrganization(params: $params) {
      _id
      name
      ownerFirstName
      ownerLastName
      ownerTitle
      ownerPhone
      ownerEmail
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
        country
      }
      active
      billing {
        planId
        status
        stripeCustomerId
        paidThrough
        nextPaymentDate
        paymentMethodDesc
      }
      payments {
        stripeChargeId
        paidDateTime
        amount
        taskId
      }
    }
  }
  
`;

export default mutation;
