import React from "react";
import styled from "styled-components";
import { Colors } from "../../util/Colors";
import Cleave from "cleave.js/react";
import { margin } from "polished";

type CallbackFunctionVariadic = (...args: any) => void;

type CallbackFunction = () => void;

type PhoneInputProps = {
   margin?: string;
   padding?: string;
   width?: string;
   error?: any;
   top?: string;
};
interface InputFieldProps {
   id?: string;
   name?: string;
   value?: string | null;
   width?: string;
   margin?: string;
   xswidth?: string;
   height?: string;
   maxWidth?: string;
   radius?: string;
   padding?: string;
   borderRadius?: string;
   top?: string;
   bottom?: string;
   left?: string;
   right?: string;
   header?: string;
   footer?: string;
   error?: any;
   placeholder?: string;
   disabled?: boolean;
   onChange?: CallbackFunction | CallbackFunctionVariadic;
   checked?: string;
   required?: string | boolean;
   minLength?: string;
   maxLength?: string | number;
   readOnly?: boolean;
   note?: string;
   suffix?: string;
}

const InputContainer: React.FunctionComponent<PhoneInputProps> = styled.div<PhoneInputProps>`
   width: 100%;
   max-width: 100%;
   margin: 0px;

   @media screen and (max-width: 991px) {
      width: "100%";
   }
   @media screen and (max-width: 450px) {
      width: 100%;
   }
`;

const PhoneInput = styled(Cleave)<PhoneInputProps>`
   max-width: 100%;
   margin: 0px;
   height: 40px;
   padding: 0px 10px;
   border-radius: 5px;
   background: ${Colors.white};
   border: 1px solid ${Colors.grey5};
   box-sizing: border-box;
   outline: none;
   font-size: 16px;
   width: ${(props) => (props.width ? props.width : "100%")};
   border: ${(props) =>
      props.error ? `1px solid ${Colors.red}` : `1px solid ${Colors.grey5}`};

   ::placeholder {
      font-size: 14px;
      font-weight: 700;
      color: ${Colors.grey3};
   }
`;
const HeaderStyle = styled.div`
   font-size: 16px;
   font-weight: bold;
   color: ${Colors.black};
   margin: ${(props: PhoneInputProps) =>
      props.top ? "0px 0px 10px 0px" : "20px 0px 8px 0px"};
`;
const FooterStyle = styled.div`
   font-size: 1.2rem;
   margin-top: 10px;
   color: ${Colors.grey3};
`;

const ErrorText = styled.div`
   color: ${Colors.red};
   font-size: 13px;
   margin-top: 5px;
   font-weight: 600;
`;
export default function PhoneNumberCleave({
   width,
   header,
   footer,
   placeholder,
   value,
   onChange,
   disabled = false,
   error,
}: InputFieldProps) {
   return (
      <>
         <InputContainer width={width || "400px"}>
            {header && <HeaderStyle className="label">{header}</HeaderStyle>}
            <PhoneInput
               // options={{ phone: true, phoneRegionCode: 'US' }}
               options={{
                  phone: false,
                  phoneRegionCode: "US",
                  blocks: [3, 3, 4],
                  numericOnly: true,
               }}
               placeholder={placeholder}
               value={value ?? ""}
               onChange={onChange}
               error={error}
               disabled={disabled}
            />
            <ErrorText>{error}</ErrorText>
            {footer && <FooterStyle>{footer}</FooterStyle>}
         </InputContainer>
      </>
   );
}
