import React, { useEffect } from "react";
import styled from "styled-components";
import Joi from "joi";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Container, MainWrapper } from "./Home";
import routePaths from "../config/routepaths.config";
import { CardHeader, CardTitle, LeftWrapper } from "./DeviceView";
import PhoneInputStyle from "../components/elements/PhoneInput";
import Button from "../components/elements/button/Button";
import Input from "../components/elements/Input";
const Logo = require("../assets/images/user.png");
import CREATE_ORGANIZATION from "../graphql/mutations/createOrganization.mutation";
import GET_ORGANIZATIONS from "../graphql/queries/getOrganizations.query";
import { GaizeState } from "../redux/store";
import { Colors } from "../util/Colors";
import Loader, { LoaderSizes } from "../components/elements/Loader";
import { LogoImage } from "./AccountDetail";
import StyledSelect from "../components/elements/DropDown";
import { USstates } from "../models/enums/USStatesEnum";
import ErrorText from "../components/elements/ErrorText";
import { storage } from "../firebase";
import GET_PLANS_DATA from "../graphql/queries/getPlans.query";
import * as ErrorUtil from "../util/ErrorUtil";
import * as Schema from "../util/Schema";
import makeEventHandler from "../util/MakeEventHandler";
import toast, { Toaster } from "react-hot-toast";

const schema = Joi.object({
   organizationName: Schema.organization.name().error(([error]) => {
      const message = "Name is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),

   plan: Schema.organization.plan().error(([error]) => {
      const message = "Plan is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),

   paymentMethod: Schema.organization.paymentMethod().error(([error]) => {
      const message = "Payment method is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),

   ownerFirstName: Schema.organization.firstName().error(([error]) => {
      const message = "First name is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),

   ownerLastName: Schema.organization.lastName().error(([error]) => {
      const message = "Last Name is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),

   email: Schema.organization.email().error(([error]) => {
      const message = "You have entered an invalid email address!";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),

   phoneNumber: Schema.organization.phoneNumber().error(([error]) => {
      const message =
         error.code === "string.min"
            ? "Phone Number is invalid"
            : "Phone Number is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),

   phoneExtension: Schema.organization.phoneExtension().error(([error]) => {
      const message =
         error.code === "string.min" && "Phone Extension is invalid";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),

   addressLine1: Schema.organization.addressLine1().error(([error]) => {
      const message = "Address Line 1 is required";
      return new Error(
         JSON.stringify({
            field: error.path[0],
            message,
         })
      );
   }),
});

interface LogoButtonProps {
   color?: string;
}

export const Form = styled.div`
   width: 70%;
   background: #f9f9f9;
   padding: 20px;
   border-radius: 7px;
   box-shadow: 7px 6px 9px 4px #00000012;
`;

const SubForm = styled.div`
   display: flex;

   @media screen and (max-width: 450px) {
      flex-direction: column;
   }
`;

const Spacer = styled.div`
   margin: 10px;
`;

const ImageContainer = styled.div`
   overflow: hidden;
   height: 80px;
   width: 80px;
   border-radius: 50%;
   border: 1px solid #ccc;
`;

const ImageUploadContainer = styled.div`
   display: flex;
   padding: 0 0 10px;
   align-items: center;
`;

const LogoButton = styled.div<LogoButtonProps>`
   color: ${(props) => props?.color ?? Colors.blue};
   padding: 2px 0;
   cursor: pointer;
`;

const LinkContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 4px 15px;
`;
export const Label = styled.div`
   font-size: 16px;
   font-weight: bold;
   color: #000000;
   margin: 0px 0px 10px 0px;
`;
export const DropDown = styled.div`
   width: 100%;
`;

export const RequiredInput = styled.div`
   position: relative;
   min-width: 358px;
   @media screen and (max-width: 567px) {
      min-width: initial;
   }
   .label {
      width: max-content;
      position: relative;
      &:before {
         content: "*";
         position: absolute;
         top: -6px;
         font-weight: 600;
         right: -10px;
         font-size: 23px;
         color: ${Colors.red};
      }
   }
`;

export enum ErrorKeyEnum {
   OrganizationName = "organizationName",
   Extension = "phoneExtension",
   Plan = "plan",
   PaymentMethod = "paymentMethod",
   FirstName = "ownerFirstName",
   LastName = "ownerLastName",
   Email = "email",
   PhoneNumber = "phoneNumber",
   AddressLine1 = "addressLine1",
}

export enum PaymentMethod {
   CreditCard = "Credit Card",
   Check = "Check",
}

export enum Countries {
   Country = "--Select Country--",
   UnitedStates = "United States",
   Canada = "Canada",
}

const AddAccount = () => {
   const navigate = useNavigate();
   const user = useSelector((state: GaizeState) => state.user as any);
   const userData = user?.organization;
   const [ownerFirstName, setOwnerFirstName] = React.useState("");
   const [ownerLastName, setOwnerLastName] = React.useState("");
   const [ownerTitle, setOwnerTitle] = React.useState("");
   const [plan, setPlan] = React.useState("");
   const [paymentMethod, setPaymentMethod] = React.useState("");
   const [organizationName, setOrganizationName] = React.useState("");
   const [orgProfile, setOrgProfile] = React.useState("");
   const [phoneNumber, setPhoneNumber] = React.useState("");
   const [phoneExtension, setPhoneExtension] = React.useState("");
   const [email, setEmail] = React.useState("");
   const [addressLine1, setAddressLine1] = React.useState("");
   const [addressLine2, setAddressLine2] = React.useState("");
   const [city, setCity] = React.useState("");
   const [state, setState] = React.useState("");
   const [zipCode, setZipCode] = React.useState("");
   const [country, setCountry] = React.useState("");
   const [loading, setLoading] = React.useState(false);
   const [imageLoading, setImageLoading] = React.useState(false);
   const [error, setError] = React.useState("");
   const [fieldErrors, setFieldErrorsInternal] = React.useState({
      [ErrorKeyEnum.Extension]: null,
      [ErrorKeyEnum.OrganizationName]: null,
      [ErrorKeyEnum.Plan]: null,
      [ErrorKeyEnum.PaymentMethod]: null,
      [ErrorKeyEnum.FirstName]: null,
      [ErrorKeyEnum.LastName]: null,
      [ErrorKeyEnum.Email]: null,
      [ErrorKeyEnum.PhoneNumber]: null,
      [ErrorKeyEnum.AddressLine1]: null,
   });

   const eventHandler = makeEventHandler(() => setError(""));

   const setFieldErrors = (field: string, message: string | null) => {
      const newFieldErrors: any = {
         [field]: message,
      };
      setFieldErrorsInternal(newFieldErrors);
   };

   const onChangeFirstName = eventHandler((value: string) => {
      setFieldErrors(ErrorKeyEnum.FirstName, null);
      setOwnerFirstName(value);
   });

   const onChangeLastName = eventHandler((value: string) => {
      setFieldErrors(ErrorKeyEnum.LastName, null);
      setOwnerLastName(value);
   });

   const onChangeName = eventHandler((value: string) => {
      setFieldErrors(ErrorKeyEnum.OrganizationName, null);
      setOrganizationName(value);
   });

   const onChangePhoneNumber = eventHandler((value: string) => {
      const phone = value?.replace(new RegExp(" ", "g"), "");
      const newPhone = phone?.replace(/^0+/, "");
      setFieldErrors(ErrorKeyEnum.PhoneNumber, null);
      setPhoneNumber(newPhone);
   });

   const onChangeEmail = eventHandler((value: string) => {
      setFieldErrors(ErrorKeyEnum.Email, null);
      setEmail(value);
   });

   const onChangeAddress1 = eventHandler((value: string) => {
      setFieldErrors(ErrorKeyEnum.AddressLine1, null);
      setAddressLine1(value);
   });

   //enum to array conversion
   const statesArray = [] as any;
   for (const [propertyKey, propertyValue] of Object.entries(USstates)) {
      if (!Number.isNaN(Number(propertyKey))) {
         continue;
      }
      statesArray.push({ label: propertyValue, value: propertyValue });
   }

   const paymentMethodOptions = [] as any;
   for (const [propertyKey, propertyValue] of Object.entries(PaymentMethod)) {
      if (!Number.isNaN(Number(propertyKey))) {
         continue;
      }
      paymentMethodOptions.push({ label: propertyValue, value: propertyValue });
   }

   const countryOptions = [] as any;
   for (const [propertyKey, propertyValue] of Object.entries(Countries)) {
      if (!Number.isNaN(Number(propertyKey))) {
         continue;
      }
      countryOptions.push({ label: propertyValue, value: propertyValue });
   }

   type selectedStateType = {
      value: string;
      label: string;
   };

   const [selectedState, setSelectedState] =
      React.useState<selectedStateType | null>(() => {
         return null;
      });
   const [selectedPlan, setSelectedPlan] =
      React.useState<selectedStateType | null>(() => {
         return null;
      });
   const [selectedPaymentMethod, setSelectedPaymentMethod] =
      React.useState<selectedStateType | null>(() => {
         return null;
      });

   const [selectedCountry, setSelectedCountry] =
      React.useState<selectedStateType | null>(() => {
         return null;
      });

   useEffect(() => {
      setState(selectedState?.value as any);
      setPlan(selectedPlan?.value as any);
      setPaymentMethod(selectedPaymentMethod?.value as any);
      setCountry(selectedCountry?.value as any);
   }, [selectedState, selectedPlan, selectedPaymentMethod, selectedCountry]);

   /*Actions*/
   const onCancel = () => {
      navigate(routePaths.SUPER_ADMIN);
   };

   // Create the file metadata
   const metadata = {
      contentType: "image/jpeg",
   };

   const onMediaDrop = (mediaFiles: any) => {
      setImageLoading(true);
      const storageRef = ref(
         storage,
         `${userData?.organization?._id}/assets/images/${mediaFiles[0].name}`
      );
      const uploadTask = uploadBytesResumable(
         storageRef,
         mediaFiles[0],
         metadata
      );
      uploadTask.on(
         "state_changed",
         (snapshot) => {
            // progrss function ....
            const progress =
               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
         },
         (error) => {
            // error function ....
            setError(error.message);
            setImageLoading(false);
         },
         () => {
            // complete function ....
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
               setOrgProfile(downloadURL);
               setTimeout(() => {
                  setImageLoading(false);
               }, 0);
            });
         }
      );
   };

   /* Create New Account function */
   const [createNewAccountMutation] = useMutation(CREATE_ORGANIZATION, {
      refetchQueries: [
         {
            query: GET_ORGANIZATIONS,
         },
      ],
      onCompleted: async (data) => {
         localStorage.setItem("addOrg", "true");
         setLoading(false);
         setError("");
         navigate(routePaths.SUPER_ADMIN);
      },
      onError: async (error) => {
         const errorMsg = ErrorUtil.getErrorMessage(error);
         if (error) {
            toast.error(errorMsg);
         }
         setLoading(false);
      },
   });
   const createNewAccount = (event?: React.FormEvent<HTMLFormElement>) => {
      if (event) {
         event.preventDefault();
      }
      const params = schema.validate({
         organizationName,
         plan,
         paymentMethod,
         ownerFirstName,
         ownerLastName,
         email,
         phoneNumber,
         phoneExtension,
         addressLine1,
      });

      const { error: schemaError } = params;
      if (schemaError) {
         const { field, message } = JSON.parse(schemaError.message);
         setFieldErrors(field, message);
         return;
      }
      setError("");
      toast.remove();
      createNewAccountMutation({
         variables: {
            params: {
               name: organizationName,
               ownerFirstName: ownerFirstName,
               ownerLastName: ownerLastName,
               ownerTitle: ownerTitle,
               ownerPhone: phoneNumber,
               ownerPhoneExten: phoneExtension,
               ownerEmail: email,
               logoUrl: orgProfile,
               address: {
                  addressLine1: addressLine1,
                  addressLine2: addressLine2,
                  city: city,
                  state: state,
                  zipCode: zipCode,
                  country: country,
               },
               active: true,
               billing: {
                  planId: plan,
                  status: "Pending",
                  paidThrough: paymentMethod,
               },
            },
         },
      });
      setLoading(true);
   };

   // GET_PLANS_DATA
   const { data } = useQuery(GET_PLANS_DATA, {
      fetchPolicy: "network-only",
   });
   const planOptions = data?.getPlans?.map((data, i) => {
      return {
         label: data.name,
         value: data._id,
      };
   });

   return (
      <>
         <MainWrapper>
            <Container>
               <CardHeader>
                  <LeftWrapper>
                     <CardTitle>Add Account</CardTitle>
                  </LeftWrapper>
               </CardHeader>
               <Form>
                  <Label> Logo </Label>
                  <ImageUploadContainer>
                     <ImageContainer>
                        {imageLoading ? (
                           <Loader
                              color={Colors.blue}
                              size={LoaderSizes.VerySmall}
                           />
                        ) : (
                           <LogoImage src={orgProfile ? orgProfile : Logo} />
                        )}
                     </ImageContainer>
                     <LinkContainer>
                        <Dropzone
                           onDrop={onMediaDrop}
                           accept={{
                              "image/*": [".jpeg", ".png"],
                           }}
                        >
                           {({ getRootProps, getInputProps }) => (
                              <div
                                 {...getRootProps()}
                                 style={{ width: "105px" }}
                              >
                                 <input {...getInputProps()} />
                                 <LogoButton> Upload Image</LogoButton>
                              </div>
                           )}
                        </Dropzone>
                        {orgProfile && (
                           <LogoButton
                              color={Colors.red}
                              onClick={() => setOrgProfile("")}
                           >
                              {" "}
                              Remove Image
                           </LogoButton>
                        )}
                     </LinkContainer>
                  </ImageUploadContainer>
                  <RequiredInput>
                     <Input
                        type="text"
                        header="Organization Name"
                        placeholder="Organization Name"
                        value={organizationName}
                        onChange={onChangeName}
                        error={fieldErrors[ErrorKeyEnum.OrganizationName]}
                        top="10px"
                     />
                  </RequiredInput>
                  <Spacer />
                  <Input
                     type="text"
                     header="Owner Title"
                     placeholder="Owner Title"
                     value={ownerTitle}
                     onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setOwnerTitle(e.target.value)
                     }
                     top="10px"
                  />
                  <Spacer />
                  <RequiredInput>
                     <SubForm>
                        <DropDown>
                           <StyledSelect
                              header="Plan"
                              value={selectedPlan}
                              onChange={({ label, value }: any) => {
                                 setSelectedPlan({ label: label, value });
                              }}
                              placeholder="Plan"
                              options={planOptions}
                           />
                           {!selectedPlan && (
                              <ErrorText margin="0 0 0 0">
                                 {fieldErrors[ErrorKeyEnum.Plan]}
                              </ErrorText>
                           )}
                        </DropDown>
                        <Spacer />
                        <DropDown>
                           <StyledSelect
                              header="Payment Method"
                              value={selectedPaymentMethod}
                              onChange={({ label, value }: any) => {
                                 setSelectedPaymentMethod({
                                    label: label,
                                    value,
                                 });
                              }}
                              placeholder="Payment Method"
                              options={paymentMethodOptions}
                           />
                           {!selectedPaymentMethod && (
                              <ErrorText margin="0 0 0 0">
                                 {fieldErrors[ErrorKeyEnum.PaymentMethod]}
                              </ErrorText>
                           )}
                        </DropDown>
                     </SubForm>
                     <Spacer />
                     <SubForm>
                        <Input
                           type="text"
                           header="Owner First Name"
                           placeholder="Owner First Name"
                           value={ownerFirstName}
                           onChange={onChangeFirstName}
                           error={fieldErrors[ErrorKeyEnum.FirstName]}
                           top="10px"
                        />
                        <Spacer />
                        <Input
                           type="text"
                           header="Owner Last Name"
                           placeholder="Owner Last Name"
                           value={ownerLastName}
                           onChange={onChangeLastName}
                           error={fieldErrors[ErrorKeyEnum.LastName]}
                           top="10px"
                        />
                     </SubForm>
                     <Spacer />
                     <Input
                        type="email"
                        header="Email"
                        placeholder="Email"
                        value={email}
                        required
                        error={fieldErrors[ErrorKeyEnum.Email]}
                        onChange={onChangeEmail}
                        top="10px"
                     />
                  </RequiredInput>
                  <SubForm>
                     <RequiredInput>
                        <PhoneInputStyle
                           placeholder="Phone Number"
                           value={phoneNumber}
                           header="Phone Number"
                           error={fieldErrors[ErrorKeyEnum.PhoneNumber]}
                           onChange={onChangePhoneNumber}
                        />
                     </RequiredInput>
                     <Spacer />
                     <Input
                        type="text"
                        header="Extension"
                        placeholder="Extension"
                        value={phoneExtension}
                        error={fieldErrors[ErrorKeyEnum.Extension]}
                        onChange={(
                           event: React.FormEvent<HTMLInputElement>
                        ) => {
                           const { value } = event.currentTarget;
                           let numbers = /^[0-9]*$/;
                           if (value.match(numbers) || value === "") {
                              setPhoneExtension(
                                 value
                                    ?.replace(new RegExp(" ", "g"), "")
                                    .toString()
                              );
                           }
                           setFieldErrors(ErrorKeyEnum.Extension, null);
                        }}
                     />
                  </SubForm>
                  <Spacer />
                  <RequiredInput>
                     <Input
                        type="text"
                        header="Address Line 1"
                        placeholder="Address Line 1"
                        value={addressLine1}
                        error={fieldErrors[ErrorKeyEnum.AddressLine1]}
                        onChange={onChangeAddress1}
                        top="10px"
                     />
                  </RequiredInput>
                  <Spacer />
                  <Input
                     type="text"
                     header="Address Line 2"
                     placeholder="Address Line 2"
                     value={addressLine2}
                     onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAddressLine2(e.target.value)
                     }
                     top="10px"
                  />
                  <Spacer />
                  <SubForm>
                     <Input
                        type="text"
                        header="City"
                        placeholder="City"
                        value={city}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                           setCity(e.target.value)
                        }
                        top="10px"
                     />
                     <Spacer />
                     <DropDown>
                        <Label>State</Label>
                        <StyledSelect
                           value={selectedState}
                           onChange={({ label, value }: any) => {
                              setSelectedState({ label: label, value });
                           }}
                           placeholder="State"
                           options={statesArray}
                        />
                     </DropDown>
                  </SubForm>
                  <Spacer />
                  <SubForm>
                     <Input
                        type="text"
                        header="ZIP/Postal Code"
                        placeholder="ZIP/Postal Code"
                        value={zipCode}
                        onChange={(
                           event: React.FormEvent<HTMLInputElement>
                        ) => {
                           const { value } = event.currentTarget;
                           setZipCode(value);
                        }}
                        top="10px"
                     />
                     <Spacer />
                     <DropDown>
                        <Label>Country</Label>
                        <StyledSelect
                           value={selectedCountry}
                           onChange={({ label, value }: any) => {
                              setSelectedCountry({ label: label, value });
                           }}
                           placeholder="Country"
                           options={countryOptions}
                        />
                     </DropDown>
                  </SubForm>
                  <Spacer />
                  <div style={{ display: "flex" }}>
                     <Button
                        margin="20px 15px 0px 0px"
                        text="Add"
                        loading={loading}
                        disabled={loading}
                        onClick={() => createNewAccount()}
                     />
                     <Button
                        margin="20px 0px 0px 0px"
                        text="Cancel"
                        onClick={() => onCancel()}
                     />
                  </div>
               </Form>
            </Container>
         </MainWrapper>
      </>
   );
};
export default AddAccount;
